import React from "react"
import CreateReportContainer from "./../create-report/CreateReportContainer"
import ReportHistoryContainer from "./../report-history/ReportHistoryContainer"

const Main = (props) => {
 return(
    <main className="main-content">
      <CreateReportContainer />
      <ReportHistoryContainer />
    </main>
  )
}

export default Main
