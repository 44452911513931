import React from "react"

interface IProps {
  shouldRender: boolean
  elseRender?: React.ReactNode
}

const ConditionalRender: React.FC<IProps> = (props) => (
  <>
    {
      props.shouldRender
        ? props.children
        : props.elseRender
      || null
    }
  </>
)

export default ConditionalRender
