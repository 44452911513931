import { config, getConfig } from "./../../config"
import request from "./../../lib/request"
import * as debug from "./../../lib/debuggers"
import { IIdentityStore } from "./../../stores/types"
import { handleTokenRejection, auth } from "../auth0/Authenticator"


/*
  Apply menu items to the header state
  @param {Store} store - the app state
  @param {array} resp - the response from the api
  @return {Promise} resolves to the store
*/

const handleGetNavSuccess = (store: IIdentityStore) => resp => {
  store.updateHeaderOptions({menu: resp})
  return Promise.resolve(store)
}

/*
  Logs failure of the request to integrasted nav API
  @return {Promise} rejected with the exception reason
*/

const handleGetNavException = reason => {
  debug.auth("getMenuItems failure:", reason)
  // log out user for 401
  handleTokenRejection(reason.response)
  return Promise.reject(reason)
}

/*
  Initiate call to integrated nav API and handle response
  @param {Store} store - the state of the app
  @param {string} token - the access token
  @return {Promise} - resolves to the app store
*/

export const getNav = (store: IIdentityStore) => {
  return getMenuItems()
    .then(handleGetNavSuccess(store))
    .catch(handleGetNavException)
}

/*
  Async call to the integrated nav API for user
  mavigation items
*/

const getMenuItems = async () => {
  const token = await auth.getToken("intNav")
  const rejection = {
    name: "menuSettingsFailure",
    msg: "Unable to retrieve menu settings at this time."
  }
  if (token) {
    return request({
      url: `${config.get("endpoints.central")}/api/v2/menuitems`,
      params: {productLine: "central"},
      headers: {"Authorization": `Bearer ${token.accessToken}`}
    })
    .then((resp) => {
      return Promise.resolve(resp.data)
    })
    .catch((reason) => {
      debug.err("Menu settings error:", reason)
      return Promise.reject(rejection)
    })
  }
  return Promise.reject(rejection)
}

export const patchUserMetadata = async (language: {id: string}) => {
  const token = await auth.getToken("intNav")
  return request({
    url: `${getConfig().get("endpoints.central")}/api/v2/usermetadata`,
    method: "PATCH",
    data: {
      language: language.id
    },
    headers: {
      "Authorization": `Bearer ${token.accessToken}`
    }
  })
}
