import React from "react"
import classNames from "classnames"

interface IProps {
  // a class or list of classes to apply to the section
  class?: string | string[]
}

/*
  A sectioning component meant for containing a defined
  piece of content. Use when there isn't a more specific
  semantic element to represent it, eg. nav, aside. Use
  in lieu of div which provides non-semantic division.
*/

const Section: React.StatelessComponent<IProps> = (props) => {
  return (
    <section className={classNames("section", props.class)}>
      {props.children}
    </section>
  )
}

export default Section
