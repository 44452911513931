import React from "react"
import {render} from "react-dom"
import { init } from "./init"

if (process.env.NODE_ENV == "development") {
  localStorage.setItem("debug", "trendsreport:*")
} else {
  localStorage.removeItem("debug")
}

declare global {
  interface Window {
    UITools
    trendsReportConfig: {
      endpoints: {
        intNav: string
        trendsReport: string
        central: string
      },
      auth0: {
        domain: string
        clientID: string
        contentSearchClientID: string
      },
      audiences: {
        intNav: string
        trendsReport: string
      },
      features: {
        showSub30Toggle: boolean
        showPandora: boolean
        resultLimit: number
      }
    }
  }
}

/* Master bootstrap stylesheet */
import "./stylesheets/vendor/_bootstrap_imports.scss"

/* The original common-ui master stylesheet */
// import "@ingrooves/common-ui/dist/style.css"

/* A copy of the common-ui master stylesheet with styles disabled */
import "./stylesheets/vendor/commonui.css"

/* A new WIP common-ui stylesheet with next design */
import "./stylesheets/vendor/commonui-next.scss"

/* App stylesheet */
import "./stylesheets/_styles.scss"

/* Import App last so that imported stylesheets load last */
import App from "./components/app/App"


const renderApp = ({auth, searcher, store, reporter, logger}) => {
  try {
    render(
      <App
        store={store}
        auth={auth}
        searcher={searcher}
        reporter={reporter}
        logger={logger}
      />,
      document.getElementById("app")
    )
  } catch (error) {
    console.error(error)
  }
}

init()
  /* Logout the user if tokens reject */
  .catch(({auth, reason}) => {
    auth.identityClient.logout()
  })
  .then(renderApp)
