import React from "react"
import { get } from "lodash"
import { inject, observer} from "mobx-react"
import ReportHistory from "./ReportHistory"
import { IHistoryStore } from "./../../stores/types"
import { IReporter } from "./../../services/reports/types"
import { ILogger } from "../../services/uiApi/types"
import ErrorBoundary from "../common/ErrorBoundary"

interface IProps {
  // inject props
  historyStore?: IHistoryStore
  reporter?: IReporter
  logger?: ILogger
}

/*
  Create Report container for handling component
  logic, lifecycle events, and state
*/

@inject("historyStore", "reporter", "logger")
@observer
class ReportHistoryContainer extends React.Component<IProps> {
  poller

  getCSV = (id: string) => {
    return this.props.reporter.getCSVPresignedKey(id).then(
      response => {
        const key = get(response, "data.key")
        return key
          ? this.props.reporter.generateDownloadURL(key, id)
          : Promise.reject(`No key from presigned response for report ${id}`)
      }
    )
  }

  /*
    Initiates the retrival and polling of report history
    on mount
   */

  componentDidMount() {
    this.props.historyStore.pollHistory()
  }

  /*
    Removes report history polling on unmount
   */

  componentWillUnmount() {
    this.props.historyStore.stopPollingHistory()
  }

  render() {
    return (
      <ErrorBoundary>
        <ReportHistory
          historyStore={this.props.historyStore}
          getCSV={this.getCSV}
        />
      </ErrorBoundary>
    )
  }
}

export default ReportHistoryContainer
