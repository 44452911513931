import { IUIStore, IRootStore, IFeatures } from "./types"
import { observable } from "mobx"
import { getConfig } from "../config"

class UIStore implements IUIStore {
  rootStore: IRootStore
  @observable features: IFeatures

  constructor(rootStore) {
    this.rootStore = rootStore
    this.features = getConfig().get("features")
  }
}

export default UIStore
