import React from "react"
import { inject, observer  } from "mobx-react"
import AsyncSelect from "react-select/lib/Async"
import { map, get } from "lodash"
import classNames from "classnames"

// Types
import { ILookupOption, ILookupDocument } from "./../../../services/lookups/types"
import { ILookupFilterProps } from "./../../../stores/types"
import { ILookupInputContext, IReactSelectEvent } from "./types"

// Components
import LookupInputOption from "./LookupInputOption"
import LookupSingleValue from "./LookupSingleValue"
import LookupInputControl from "./LookupInputControl"
import LookupInputClearIndicator from "./LookupInputClearIndicator"

// Services
import Searcher from "../../../services/lookups/searcher"
import * as debug from "../../../lib/debuggers"

// Stylesheet
import "./labeledlookup.scss"
import Popover from "../popover/Popover"
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"

export interface IProps extends ILookupFilterProps, ITranslatorProps {
  // callback for dropdown item (option) clicks
  onChange?: (selected: ILookupDocument) => void
  onClear?: (filterId: string, itemId?: number) => void
  onMouseEnter?: (e) => void
  onMouseLeave?: (e) => void
  pendingClearVisible: boolean
  isPendingClear: boolean
  id: string
  isClearable?: boolean
  // Whether the dropdown should stay open between item clicks
  keepOpen?: boolean
  label: string
  multiSelectLimit?: number
  // tells us whether to prepopulate the dropdown
  prepopulate?: boolean
  // injected props
  params?: {label?: string[], gtin?: string[]}
  searcher?: Searcher
  selectedLookups: ILookupDocument[]
  noResults: string
  placeholder: string
  locked?: boolean
}

/*
  LookupInput component is a dropdown with a label
  to the left
*/

@inject("searcher")
@observer
export class LookupInput extends React.Component<IProps> {
  asyncSelect
  wrapperNode
  q: string = ""

  constructor(props) {
    super(props)
    this.lookupOptions = this.lookupOptions.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.noOptionsMessage = this.noOptionsMessage.bind(this)
  }

  handleSelectOption = (selected: ILookupOption) => {
    this.props.onChange(selected.document)
  }

  handleItemClick(selection: ILookupOption[], event: IReactSelectEvent) {
    switch (event.action) {
      case "remove-value":
        this.props.onClear(this.props.id, get(event, "removedValue.value"))
        break
      case "clear":
        this.props.onClear(this.props.id)
        break
      case "select-option":
        if (this.props.multiselect && selection.length > 0) {
          this.handleSelectOption(selection[selection.length - 1])
          break
        }
        this.handleSelectOption(selection[0])
        break
      default:
        break
    }
  }

  handleChange = (q: string, event: IReactSelectEvent) => {
    if (event.action == "input-change") {
      this.q = q
    }
  }

  lookupOptions(query: string): Promise<ILookupOption[]> {
    let params = Object.assign({
      q: query,
    }, this.props.params)

    return this.props.searcher.getSuggestions(this.props.endpoint, params)
    .then(
      (response) => {
        const out = map(response, (doc) => {
          const type = doc._type
          const label = get(doc, `${type}.name`, this.props.labelField)
          const value = get(doc, `${type}.primaryKey`, this.props.valueField)
          return {
            label,
            value,
            type: doc._type,
            context: this.buildContext(doc),
            document: doc
          }
        })
        debug.lookups(out)
        return Promise.resolve(out)
      }
    )
  }

  buildContext(doc: any): ILookupInputContext[] {
    const type = doc._type
    const context: ILookupInputContext[] = []
    switch (type) {
      case "label":
        if (doc.label.parentLabel) {
          context.push(this.newContextItem("parentLabel",
            doc.label.parentLabel.name))
        } else {
          context.push(this.newContextItem("topLevelLabel", true))
        }
        break
      case "album":
        context.push(this.newContextItem("gtin", doc.album.primaryKey))
        context.push(this.newContextItem("artist", doc.album.artist.name))
        context.push(this.newContextItem("label", doc.album.label.name))
        break
      case "song":
        context.push(this.newContextItem("isrc", doc.song.primaryKey))
        context.push(this.newContextItem("artist", doc.song.artist.name))
        context.push(this.newContextItem("label", doc.song.label.name))
        break
      default:
        break
    }

    return context
  }

  newContextItem(contextItemKey: string, value: string | boolean) {
    const item = {...LookupInput.contextItems[contextItemKey]}
    if (!item) return
    item.label = this.props.translate(item.labelKey)
    item.value = value
    return item
  }

  static contextItems:{ [key: string]: ILookupInputContext } = {
    "parentLabel": {
      field: "parentLabel",
      label: "",
      labelKey: "label.parent",
      type: "string",
      value: ""
    },
    "topLevelLabel": {
      field: "topLevelLabel",
      label: "",
      labelKey: "label.topLevel",
      type: "boolean",
      value: ""
    },
    "isrc": {
      field: "isrc",
      label: "",
      labelKey: "isrc",
      type: "string",
      value: ""
    },
    "gtin": {
      field: "gtin",
      label: "",
      labelKey: "gtin",
      type: "string",
      value: ""
    },
    "artist": {
      field: "artist",
      label: "",
      labelKey: "breakout.artist",
      type: "string",
      value: ""
    },
    "label": {
      field: "label",
      label: "",
      labelKey: "label",
      type: "string",
      value: ""
    }
  }

  noOptionsMessage({ inputValue }): string {
    return inputValue.length
      ? this.props.noResults
      : this.props.placeholder
  }

  render() {
    let values = this.props.selectedLookups
    return (
      <div
        className="ing-labeled-select"
        ref={ref => this.wrapperNode = ref}
      >
        <Popover
          hide={!this.props.locked}
          text={this.props.translate("tooltip.lockedLookupField")}
          renderOnHover
          classNames="react-select-container"
        >
          <AsyncSelect
            value={values}
            isOptionSelected={() => false}
            ref={(ref) => this.asyncSelect = ref}
            className={classNames(
              "react-select-container",
              {
                "pending-clear": this.props.pendingClearVisible
                  && this.props.isPendingClear
              }
            )}
            classNamePrefix="react-select"
            onChange={this.handleItemClick}
            onInputChange={this.handleChange}
            isClearable={this.props.isClearable}
            placeholder={this.props.placeholder}
            loadingMessage={() => this.props.translate("lookups.loading")}
            loadOptions={this.lookupOptions}
            defaultOptions={this.props.prepopulate}
            isMulti={this.props.multiselect}
            components={{
              Option: LookupInputOption,
              SingleValue: LookupSingleValue,
              Control: (props) => (
                <LookupInputControl
                  {...props}
                  label={this.props.label}
                />
              ),
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              ClearIndicator: (props) => (
                <LookupInputClearIndicator
                  {...props}
                  filterId={this.props.id}
                  onMouseEnter={(e) => {
                    this.asyncSelect.blur()
                    this.props.onMouseEnter(e)
                  }}
                />
              )
            }}
            noOptionsMessage={this.noOptionsMessage}
            isDisabled={this.props.locked}
          />
        </Popover>
      </div>
    )
  }
}

export default InjectTranslatorHOC(LookupInput)
