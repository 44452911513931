import React, { useState } from "react"
import classNames from "classnames"
import Section from "./../../common/sections/Section"
import { ICreateReportStore, IFiltersStore, INotificationsStore } from "../../../stores/types"
import { inject, observer } from "mobx-react";
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"
import DateRangePicker from "./DateRangePicker"
import { config } from "../../../config"

interface IProps extends ITranslatorProps {
  filtersStore?: IFiltersStore
  notificationsStore?: INotificationsStore
  createReportStore?: ICreateReportStore
}

export const CreateReportDateRangeSelection: React.FC<IProps> =
  inject(
    "filtersStore", "notificationsStore", "createReportStore"
  )(observer((props) => {
    const [dateFrom, setDateFrom] = useState<Date>()
    const [dateTo, setDateTo] = useState<Date>()

    const { filtersStore, notificationsStore, createReportStore } = props
    const reportingType = config.get("features.reportingType")

    const handleDateChange = (dateRange: Date[]) => {
      const start = dateRange[0]
      const end = dateRange[1]

      setDateFrom(start)
      setDateTo(end)

      if (start && end) {
        filtersStore.setDateRange(start, end)
        if (notificationsStore.notifications.has("dateRange")) {
          createReportStore.validateDateRange()
        }
      }
      if (!start && !end) {
        filtersStore.clearDateRange()
      }
    }

    return (
      <Section
        class={classNames(
          ["create-report-daterange","flex-item-2", "flex-column"],
          {"invalid-input": notificationsStore.notifications.has("dateRange")}
        )}
      >
        <label className="h2">{props.translate("dateRange.header")}</label>
        <div>
          <DateRangePicker
            monthPicker={reportingType === "SALES"}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateChange={handleDateChange}
          />
        </div>
      </Section>
    )
}))

export default InjectTranslatorHOC(CreateReportDateRangeSelection)
