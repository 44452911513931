import React from "react"
import { css } from "emotion"
import classNames from "classnames"
import { ILookupInputOptionProps as Props } from "./types"
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"
import { observer } from "mobx-react"

export const LookupInputOption = observer((props: Props & ITranslatorProps) => {
  // copied from
  // `https://github.com/JedWatson/react-select/blob/master/src/components/Option.js`
  const {
    data, cx, getStyles, isDisabled, isFocused,
    isSelected, innerRef, innerProps
  } = props;

  return (
    <div
      ref={innerRef}
      className={cx(
        css(getStyles("option", props)),
        {
          "option": true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
          "from-history": data.document.fromHistory
        },
        "labeled-lookup-option"
      )}
      {...innerProps}
    >
      <span className="primary">{data.label}</span>

      {data.context &&
        data.context.map((contextConfig) => {
          const label = contextConfig.label
          const value = contextConfig.type === "boolean"
            ? contextConfig.label : contextConfig.value
          return !!value && (
            <span className="context" key={contextConfig.field}>
              <span className={classNames(
                "context-label",
                { "has-value": contextConfig.type !== "boolean" }
              )}>
                { label }
              </span>

              {contextConfig.type !== "boolean" && <span>{ value }</span>}
            </span>
          )
        })}
    </div>
  )
})

export default InjectTranslatorHOC(LookupInputOption)
