import React from "react"
import { observer, inject } from "mobx-react"
import { partition, includes, sortBy, debounce } from "lodash"
import { IFiltersStore } from "./../../../stores/types"
import Filter from "./../Filter"
import Section from "./../../common/sections/Section"
import { lookupFiltersMeta } from "./../../../stores/constants"
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"

interface IProps extends ITranslatorProps {
  filtersStore?: IFiltersStore
}

interface IState {
  pendingClearVisible: boolean
  selectedLookupField: string
}

/*
  The Filter section of the Create Report UI.
  Will contain an unknown number of Filter components,
  one for each Breakout.
*/

@inject("filtersStore")
@observer
export class CreateReportFilterSection extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      pendingClearVisible: false,
      selectedLookupField: ""
    }
  }

  enableShowPendingClear = () => {
    this.setState({pendingClearVisible: true})
  }

  disableShowPendingClear = () => {
    this.setState({pendingClearVisible: false})
  }

  setSelectedLookupField = (field: string) => {
    this.setState({selectedLookupField: field})
  }

  /*
    Clears lookupsPendingClear when the mouse is moved over
    any element that is not the clear button.
    This is needed for when onMouseLeave on the clear button
    is not fired due to browser optimization during fast
    move movements.
   */

  handleMouseOut = (e: React.MouseEvent) => {
    var x = e.clientX, y = e.clientY,
    elementMouseIsOver = document.elementFromPoint(x, y);
    if (!elementMouseIsOver.closest(".react-select__clear-container") &&
        this.props.filtersStore.lookupFiltersPendingClear.length) {
        this.disableShowPendingClear()
    }
  }

  debounceMouseMove = debounce(this.handleMouseOut, 100)

  handleMouseMove = (e: React.MouseEvent) => {
    e.persist()
    this.debounceMouseMove(e)
  }


  filter = (filterId, i) => {
    const filter = this.props.filtersStore.filters.get(filterId)
    let isLocked
    if (filterId === "gtin" || filterId === "isrc") {
      const isSelected =
        filter.selectedValues && filter.selectedValues.length > 0
      if (isSelected && this.state.selectedLookupField !== filterId) {
        this.setSelectedLookupField(filterId)
      }
      if (!isSelected && this.state.selectedLookupField === filterId) {
        this.setSelectedLookupField("")
      }

      isLocked = this.state.selectedLookupField !== ""
        && this.state.selectedLookupField !== filterId
    }

    return (
      <Filter
        key={`${filter.id}-${i}`}
        filter={filter}
        updateFilter={this.props.filtersStore.updateFilter}
        updateLookupFilters={this.props.filtersStore.updateLookupsSelected}
        onMouseEnter={this.enableShowPendingClear}
        onMouseLeave={this.disableShowPendingClear}
        pendingClearVisible={this.state.pendingClearVisible}
        locked={isLocked}
      />
    )
  }

  render() {
    const { filtersStore } = this.props
    const filterGroups = partition(
      filtersStore.filtersList,
      (filterId) => includes(Object.keys(lookupFiltersMeta), filterId)
    )

    const lookups = sortBy(
      filterGroups[0], (filterId => lookupFiltersMeta[filterId].sort)
    )

    return (
      <Section
        class={["create-report-filter", "flex-column"]}
      >
        <h2>{this.props.translate("lookups.header")}</h2>
        <div>
          <div
            className="lookup-filters"
            onMouseMove={this.handleMouseMove}
          >
            {lookups.map(this.filter)}
          </div>
          <div className="flex-row flex-wrap">
            {filterGroups[1].map(this.filter)}
          </div>
        </div>
      </Section>
    )
  }
}

export default InjectTranslatorHOC(CreateReportFilterSection)
