import React from "react"
import classNames from "classnames"
import { IDropdownTriggerProps } from "./interface"
import { observer } from "mobx-react"

/*
  A button that controls the visibility of the dropdown
  and display the current selection.
*/

export const DropdownTrigger =
  observer((props: IDropdownTriggerProps) => {
  return (
    <button
      type="button"
      style={props.maxWidth && {maxWidth: props.maxWidth}}
      className={classNames(
        "btn btn-default dropdown-toggle",
        {"disabled": props.disabled}
      )}
      id={props.id}
      onClick={props.handleTriggerClick}
      aria-haspopup="true"
      aria-expanded="true">
      <span className="btn-text pr-1 ellipsis">
        {
          props.displayText ?
            <span>{props.displayText}</span> :
            <span>{props.selectedValues.join(", ")}</span>
        }
      </span>
      <span className="caret" />
    </button>
  )
})

export default DropdownTrigger
