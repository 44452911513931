import React, { ReactNode } from "react"
import {mapKeys, has} from "lodash"

interface IReplacement {
  value?: ReactNode
  key?: string
  render?(value: ReactNode, key: string): ReactNode
}

export interface IReplacements {
  [key: string]: IReplacement
}

/*
  Returns a single string of joined segment values
*/

const joinSegments = (replacements: (IReplacement | string)[]): string => {
  return replacements.reduce<string>(
    (result: string, replacement: IReplacement | string) => {
      if (typeof replacement === "string") {
        return [result, replacement].join("");
      } else if (has(replacement, "value")) {
        return [result, replacement.value].join("");
      }
      return result;
    },
    ""
  );
}

interface IProps {
  template: string
  replacements: IReplacements
}

/*
  A component that renders an outer span with each template segment value
  wrapped in a span. If the segment contains a render function, the function
  is called with the segment's value and key.
*/

const InterpolatedString = (props: IProps) => {
  const segments = defineSegmentReplacements(props)

  return (
    <React.Fragment>
    {segments.map(
      (replacement: IReplacement, index: number) => {
        if (replacement.render) {
          const key = `${replacement.key || replacement.value || ""}_${index}`
          return replacement.render(replacement.value, key)
        } else if (replacement.value) {
          const key = `${replacement}_${index}`
          return <span key={key}>{replacement.value}</span>
        } else if (typeof replacement === "string") {
          const key = `${replacement}_${index}`
          return <span key={key}>{replacement}</span>
        }
        return null
      }
    )}
  </React.Fragment>
  )
}

/*
  Returns an object re-keyed with handlebar-like template expressions
  of variable names, eg.
  {
    {{LIST}}: {
      value: "filter1", "filter2"
    }
  }
*/

const transformReplacements = (replacements: IReplacements) => {
  return mapKeys(replacements, (value, key) => {
    return `{{${key}}}`
   })
}

/*
  Returns a regex capturing group of template expressions
  eg. /({{LIST}}/g
*/

const generateRegExp = (keyMap: IReplacements) => {
  return new RegExp(`(${Object.keys(keyMap).join("|")})`,"g")
}

/*
  Returns the template as an array of segments including both the
  template expressions and non-changing values
  eg. [
    "+",
    "{{NUM}}",
    " sub-labels"
  ]
*/

const templateToArray = (template: string, regex: RegExp) => {
  return template && template.split(regex) || []
}

/*
  Returns an array of template segments with each template expression
  replaced with an object defining the replacement.
  eg. [
    {value: "8"},
    "sub-labels"
  ]
*/

const defineSegmentReplacements =
({template, replacements}: IProps): (IReplacement | string)[] => {
  let keyMap = transformReplacements(replacements)
  const r = generateRegExp(keyMap)
  const templateSegments = templateToArray(template, r)
  return templateSegments.map(segment => {
    if (segment.match(r)) {
      return (keyMap[segment] as IReplacement)
    }
    return segment
  })
}

// Returns an interpolated string
// or, if join=false, an array of values

export const interpolate =
function({template, replacements}: IProps): string {
  const segments = defineSegmentReplacements({template, replacements})
  return joinSegments(segments)
}

export default InterpolatedString
