import React from "react"
import classNames from "classnames"

export interface IButtonProps {
  // text displayed in the button
  text: string,
  // html tag to use, default: "button"
  tag?: "a" | "button" | "input",
  // type of input or button, default: "button"
  type?: "submit" | "button",
  // disabled state of the button
  disabled?: boolean
  // a icon css class string to apply to the icon span tag,
  // eg "fas fa-arrow-alt-right"
  iconClass?: string,
  // bootstrap class of the button
  style?: "primary" | "success" | "info" | "warning" | "danger",
  // classes to apply to the button tag
  classList?: string | string[]
  // whether the button should be full width of the parent
  blockLevel?: boolean,
  // position of icon relative to the text
  iconPosition?: "left" | "right",
  handleClick?: () => void
}


const Button: React.StatelessComponent<IButtonProps> = (props) => {
  const tagValue = props.tag || "button"
  const Tag: any= `${tagValue}`
  const iconOrder = props.iconPosition == "right" ? 1 : -1
  const btnClasses = classNames(
    "btn", `btn-${props.style || "default"}`, "flex-row", "flex-center",
    props.classList,
    {"btn-block": props.blockLevel}
  )

  const handleClick = () => {
    if (props.handleClick) props.handleClick()
  }

  return (
    <Tag
      className={btnClasses}
      style={{order: 0}}
      type={props.tag == "a" ? undefined : props.type || "button"}
      title={props.text}
      disabled={tagValue == "button" && props.disabled}
      onClick={handleClick}
    >
      <span className="btn-text">{props.text}</span>
        <i className={props.iconClass} style={{order: iconOrder}}/>
    </Tag>
  )
}

export default Button
