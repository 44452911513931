import React from "react"

interface IProps {
  // the value of the radio option, eg. 'streaming'
  value: string
  // how the radio text should display, eg. 'Streams'
  displayName: string
  // the name of the group of options, eg. 'metric'
  name: string
  // whether the option should be selected
  selected?: boolean
  // event listener for value change
  handleChange?: (metric) => void
}

/*
  A radio input that displays a single option
  within a list of radio options
*/

const Radio = (props: IProps) => {
  return (
    <div className="radio">
      <label>
        <input
          type="radio"
          name={props.name}
          value={props.value}
          checked={props.selected}
          onChange={e => props.handleChange(e.target.value)}
        />
        {props.displayName}
      </label>
    </div>
  )
}

export default Radio
