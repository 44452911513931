import React from "react"

export interface IFieldsetProps {
  // a heading/label to apply to the fieldset
  legend?: string
}

/*
  A fieldset component intended to contain children input elements.
  Displays a legend (label) if 'legend' prop is provided.
*/

const Fieldset: React.StatelessComponent<IFieldsetProps> = (props) => {
  return (
    <fieldset>
      {props.legend && <legend>{props.legend}</legend>}
      {props.children}
    </fieldset>
  )
}

export default Fieldset
