import React from "react"
import * as debug from "./../../lib/debuggers"
import { config } from "../../config"

interface IProps {
  reportId: string
  getCSV: (id: string) => Promise<string>
}

class FileDownload extends React.Component<IProps> {
  anchorNodeRef

  /*
    Handles download file click.
    generates a CSV download URL, adds it to link
    href, then triggers download.
   */

  handleClick = () => {
    return this.props.getCSV(this.props.reportId).then(
      downloadURL => {
        if (downloadURL) {
          this.anchorNodeRef.href = downloadURL
          this.anchorNodeRef.click()
        } else {
          throw new Error("Unable to download at this time.")
        }
      }
    ).catch(reason => {
      debug.err(reason)
    })
  }

  render() {
    const reportingType = config.get("features.reportingType")
    return (
      <span>
        <span onClick={this.handleClick} className="file-download">
          <i className="fas fa-file-download" />
          {this.props.children}
        </span>
        <a
          style={{display: "hidden"}}
          ref={ref => this.anchorNodeRef = ref}
          className="hidden"
          download={`${reportingType}_${this.props.reportId || ""}.csv`}
        />
      </span>
    )
  }
}

export default FileDownload
