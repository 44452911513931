import { get } from "lodash"
import request from "../../lib/request"
import * as debug from "../../lib/debuggers"
import { ILogger, ILogMessage } from "./types"
import { auth } from "../auth0/Authenticator"

export default class LogClient implements ILogger {
  logError = async (logBody: ILogMessage) => {
    const token = await auth.getToken("trendsReport")
    const accessToken = get(token, "accessToken")
    return request({
      method: "post",
      url: "/api/v1/log",
      data: {
        ...logBody,
        label: "UIClient",
        username: get(token, "idToken.name"),
        email: get(token, "idToken.email"),
      },
      headers: { "Authorization": `Bearer ${accessToken}` }
    }).catch(err => {
      const status = get(err, "response.status")
      debug.err(`Failed to log to server. Code: ${status} Error: ${err}`)
    })
  }
}
