import { observable, action } from "mobx"
import { INotificationsStore, NotificationsType, IRootStore } from "./types"

class NotificationsStore implements INotificationsStore {
  rootStore: IRootStore
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  notifications = observable(
    <NotificationsType>new Map(),
    { name: "notifications" }
  )

  /*
    Set the notification in notifications Map at key/notificationId
    @param { object } - key of notification id and value of Notification object
  */

  @action setNotifications = (notifications) => {
    this.notifications.merge(notifications)
  }

  /*
    Clears the notifcation from notifcations Map by key/notificationId
    @param { string } notificationId - a unique id
  */

  @action clearNotification = (notificationId: string) => {
    this.notifications.delete(notificationId)
  }

  /*
    Clears all notifcations of a certain type (eg. reportSubmit)
  */

  @action clearNotificationsByType = (notificationType: string) => {
    Array.from(this.notifications.values()).filter(notification => {
      return notification.type === notificationType
    }).forEach(notification => {
      this.notifications.delete(notification.id)
    })
  }

  /*
    Clears all the notifications in notifications Map
  */

  @action clearAllNotifications = () => {
    this.notifications.clear()
  }
}

export default NotificationsStore
