import React from "react"
import { inject, observer } from "mobx-react"
import CreateReport from "./CreateReport"
import { INotification, INotificationsStore, ICreateReportStore, IHistoryStore } from "./../../stores/types"
import { IReporter } from "./../../services/reports/types"
import { reportSubmissionSuccess, reportSubmissionError } from "./../../stores/notifications"
import * as debug from "./../../lib/debuggers"
import InjectTranslatorHOC, { ITranslatorProps } from "../InjectHOC"
import ErrorBoundary from "../common/ErrorBoundary"


interface IProps extends ITranslatorProps {
  notificationsStore?: INotificationsStore
  createReportStore?: ICreateReportStore
  historyStore?: IHistoryStore
  reporter?: IReporter
}

/*
  Create Report container for handling component
  logic, lifecycle events, and state
*/

@inject("notificationsStore", "createReportStore", "historyStore", "reporter")
@observer
export class CreateReportContainer extends React.Component<IProps> {

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.submitReport = this.submitReport.bind(this)
  }

  /*
    @param { object } reportResponse - an error object
    return { Promise<Notification> } -
    Promise with value of a success notification
   */

  private handleSubmitReportSuccess = reportResponse => {
    debug.api("Report Submit Success Response:", reportResponse)
    this.props.createReportStore.updateReportSubmittingState(false)
    // Get an updated history
    this.props.historyStore.pollHistory()
    return Promise.resolve(
      reportSubmissionSuccess(reportResponse)
    )
  }

  /*
    @param { object } reason - an error object
    return { Promise<Notification> } -
    Promise with value of an error notification
   */

  private handleSubmitReportFailure = reason => {
    this.props.createReportStore.updateReportSubmittingState(false)
    return Promise.reject(
      reportSubmissionError()
    )
  }

  /*
    Submits a report to /report/submit
    @return { Promise }
   */

  async submitReport() {
    const { createReportStore, reporter } = this.props
    createReportStore.updateReportSubmittingState(true)
    return reporter.submitReport(createReportStore.reportRequest)
      .then(this.handleSubmitReportSuccess)
      .catch(this.handleSubmitReportFailure)
  }

  /*
    Sets a notification message with the response data
    @param { Notification } a notification object
  */

  setRequestResponseNotification = (notification: INotification) => {
    this.props.notificationsStore.setNotifications({
      [notification.id]: notification
    })
  }

  /*
    Submits response if it passes validation
    @return { Promise<Notification> } a Promise with a notification response
  */

  handleSubmit() {
    if (this.props.createReportStore.validateSubmission()) {
      return this.submitReport()
        .then((response) => {
          this.setRequestResponseNotification(response)
          return Promise.resolve(response)
        })
        .catch(reason => {
          this.setRequestResponseNotification(reason)
        })
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <CreateReport
          notifications={this.props.notificationsStore.notifications}
          clearNotification={this.props.notificationsStore.clearNotification}
          handleClear={this.props.createReportStore.resetReportForm}
          handleSubmit={this.handleSubmit}
          reportSubmitting={this.props.createReportStore.reportSubmitting}
          reportHeader={this.props.translate("report.headerText")}
        />
      </ErrorBoundary>
    )
  }
}

export default InjectTranslatorHOC(CreateReportContainer)
