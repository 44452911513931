import { endOfDay, endOfMonth, startOfDay, startOfMonth, sub } from "date-fns"

export type IPresetDateRange = {
  [key: string]: Date[]
}

export type IPresets = IPresetDateRange[]

export const monthDateRangeSelectorPresets: IPresets = [
  { "datePicker.range.currentMonth": [
    startOfMonth(new Date()),
    endOfMonth(new Date())] },
  { "datePicker.range.lastMonth": [
    startOfMonth(sub(new Date(), { months: 1 })),
    endOfMonth(sub(new Date(), { months: 1 }))] },
  { "datePicker.range.last3Months": [
    startOfMonth(sub(new Date(), { months: 3 })),
    endOfMonth(sub(new Date(), { months: 1 }))] },
  { "datePicker.range.last6Months": [
    startOfMonth(sub(new Date(), { months: 6 })),
    endOfMonth(sub(new Date(), { months: 1 }))] },
  { "datePicker.range.lastYear": [
    startOfMonth(sub(new Date(), { months: 0, years: 1 })),
    endOfMonth(sub(new Date(), { months: 1 }))] },
]

export const dayDateRangeSelectorPresets: IPresets = [
  { "datePicker.range.today": [
    startOfDay(new Date()),
    endOfDay(new Date())] },
  { "datePicker.range.yesterday": [
    startOfDay(sub(new Date(), { days: 1 })),
    endOfDay(sub(new Date(), { days: 1 }))] },
  { "datePicker.range.last7Days": [
    startOfDay(sub(new Date(), { days: 6 })),
    endOfDay(new Date())] },
  { "datePicker.range.last30Days": [
    startOfDay(sub(new Date(), { days: 29 })),
    endOfDay(new Date())] },
  { "datePicker.range.thisMonth": [
    startOfMonth(new Date()),
    endOfDay(new Date())] },
  { "datePicker.range.lastMonth": [
    startOfMonth(sub(new Date(), { months: 1 })),
    endOfMonth(sub(new Date(), { months: 1 }))] },
]
