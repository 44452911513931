import React from "react"
import classNames from "classnames"

/* Popover stylesheet */
import "./popover.scss"
import ConditionalRender from "../ConditionalRender"

interface IProps {
  // a render function which renders a component as the trigger
  // renderTrigger: (state) => JSX.Element,
  // a render function which renders a component as the popover content
  renderPopover?: (state) => JSX.Element
  text?: string
  renderOnHover?: boolean
  classNames?: string | string[]
  hide?: boolean
}

export interface IPopoverState {
  popoverVisible: boolean
}

/*
  Popover is similar to Bootstrap's popover,
  it expands from a trigger element to reveal additional
  content. Unlike Bootstrap's popover, which only allows
  for a text header and text content, any component can
  be rendered in the popover.
*/

class Popover extends React.Component<IProps, IPopoverState> {
  componentRef

  constructor(props) {
    super(props)
    this.state = {
      popoverVisible: false
    }
    this.togglePopover = this.togglePopover.bind(this)
  }

  /*
    Applies an event listener to the document when component mounts
    to handle clicks off component
  */

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  /*
    Removes event listener from document which listens for off
    component clicks
  */

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  /*
    @param target: the node that received the event
    returns {boolean} - true if the popover is open and
    contains the target node
  */

  isOutsideClick(target) {
    return (
      this.state.popoverVisible &&
      this.componentRef &&
      !this.componentRef.contains(target)
    )
  }

  /*
    Event listener for off component mousedown
    @param event: {MouseEvent} - the event object
  */

  handleClickOutside = (event) => {
    if (this.isOutsideClick(event.target)) {
      this.setState({popoverVisible: false})
    }
  }

  /*
    Event handler for when user clicks the popover trigger.
    Toggles the visibility of the popover.
  */

  togglePopover() {
    this.setState({
      popoverVisible: !this.state.popoverVisible
    })
  }

  render() {
    const popoverClasses = classNames(
      ["ing-popover", {
        "hide": !this.state.popoverVisible,
        "show": this.state.popoverVisible,
      }]
    )
    return (
      <ConditionalRender
        shouldRender={
          !this.props.hide && (!!this.props.renderPopover || !!this.props.text)
        }
        elseRender={this.props.children}
      >
        <div
          className={classNames("ing-popover-wrapper", this.props.classNames)}
          ref={ref => this.componentRef = ref}
        >
          <div
            className="ing-popover-trigger"
            onClick={this.props.renderOnHover ? null : this.togglePopover}
            onMouseEnter={this.props.renderOnHover ? this.togglePopover : null}
            onMouseLeave={this.props.renderOnHover ? this.togglePopover : null}
          >
            { this.props.children }
          </div>
          <div className={popoverClasses}>
            <div className="ing-popover-inner">
              { this.props.renderPopover
                  ? this.props.renderPopover(this.state)
                  : this.props.text }
            </div>
          </div>
        </div>
      </ConditionalRender>
    )
  }
}

export default Popover
