import { identityClient, identityClientContentSearch, IIdentityClient, getConfig } from "../../config"
import * as debug from "./../../lib/debuggers"
import LogClient from "../uiApi/LogClient"
import singleton from "singleton"
import { IdentityClient } from "@ingrooves/identity-web"

const handleTokenException = reason => {
  debug.auth(reason)
  return Promise.resolve({})
}

const permissionsKey = "https://identity.ingrooves.com/claims/permissions"

export interface IdToken {
  name: string
  email: string
  sub: string
  "https://identity.ingrooves.com/claims/user_metadata": {
    language: string
  }
}

export interface IToken {
  accessToken: string,
  audience: string,
  expiresAt: string,
  idToken: IdToken
  scope: string,
}

interface ITokenRequestOptions {
  forceAuthorize?: boolean
}

export interface IAuthenticator {
  getToken(audienceKey: string, options?: ITokenRequestOptions): Promise<any>
  checkPermissions: (authResult) => Promise<any>
  authenticate: (options?: ITokenRequestOptions) => Promise<any>
}

export const handleTokenRejection = (response) => {
  if (response && [401].includes(response.status)) {
    (new LogClient()).logError({
      level: "warn",
      message: "Token rejected. Logging out.",
      response: response,
      status: response.status,
    })
    identityClient.logout()
  }
}

/*
  Gets the user information
  and auth tokens
*/

export class Authenticator extends singleton {
  identityClient: IIdentityClient
  contentSearchAuthClient: IdentityClient

  /*
    Initializes the identity-web client
    and sets the api audiences
  */

  constructor() {
    super()
    this.identityClient = identityClient
    this.contentSearchAuthClient = identityClientContentSearch
    window["trendsReportLogout"] = () => {
      this.identityClient.logout()
    }
  }

  /*
    Gets a token for a given audience
    @param {string} audience - identifier of the target API
    @return {Promise} - resolved with the token response
    or rejected with an error message
  */

  getToken = (audienceKey: string, options?: ITokenRequestOptions) => {
    const audience = getConfig().get(`audiences.${audienceKey}`)
    return this.identityClient.renew(audience, options).then(
      authResult => {
        const out = Object.assign({}, authResult, {audience})
        return Promise.resolve(out)
      }
    ).catch(reason => {
      return Promise.reject(`Unable to renew JWT for ${audienceKey}.`)
    })
  }

  getContentSearchToken = (options?: ITokenRequestOptions) => {
    const audience = getConfig().get(`audiences.contentSearch`)
    return this.contentSearchAuthClient.renew(audience, options)
    .then(
      authResult =>  Promise.resolve(Object.assign({}, authResult, {audience})))
    .catch(reason => Promise.reject(`Unable to renew JWT for contentSearch.`))
  }

  checkPermissions = response => {
    const permissions = response.idToken[permissionsKey]
    if (permissions.indexOf("read:reports") < 0) {
      return this.identityClient.logout()
    }
    return response
  }

  /*
    Gets tokens for TrendsReport, Integrated Nav, and Lookups audiences.
  */

  authenticate = (options?: ITokenRequestOptions) => {
    let requestTokens
    if (getConfig()) {
      requestTokens = Promise.all([
        this.getToken("intNav", options)
          .catch(handleTokenException),
        this.getToken("trendsReport", options)
          .then(this.checkPermissions)
          .catch(handleTokenException)
      ])
    } else {
      requestTokens = Promise.reject(
        "No audiences available to generate tokens."
      )
    }

    return requestTokens.then((tokens) => {
      /* Reject if all tokens fails */
      if (tokens.map(token => token.audience).length == 0) {
        return Promise.reject("No valid tokens present.")

      /* Map the tokens by audience keys */
      } else {
        const tokenMap = new Map()
        tokens.forEach(token => {
          tokenMap.set(token.audience, token)
        })
        return Promise.resolve(tokenMap)
      }
    })
  }
}

export const auth: Authenticator = new Authenticator()
