import React from "react"

const CheckIconPlaceholder: React.StatelessComponent = () => {
  return (
    <svg
      className="CheckIconPlaceholder"
      width="23"
      height="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.5"
        cy="16.5"
        r="11.5"
        transform="translate(-5 -5)"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default CheckIconPlaceholder
