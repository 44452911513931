import { auth, IToken } from "./services/auth0/Authenticator"
import RootStore from "./stores/RootStore"
import Searcher from "./services/lookups/searcher"
import Logger from "./services/uiApi/LogClient"
import {getNav} from "./services/integratedNav/intNavClient"
import TrendsReportClient from "./services/reports/TrendsReportClient"
import { config, getConfig } from "./config"
import { get } from "lodash"
import { IRootStore } from "./stores/types"

type ITokens = Map<string, IToken>

const fetchMenu = ({store}: {store: IRootStore}) => {
  /* Get menu items for the user */
  getNav(store.identity)
  /* If menu items fails, proceed without a menu */
  .catch(reason => {
    return Promise.resolve()
  })

  return Promise.resolve({store,auth})
}

const fetchTranslations = async ({...data}) => {
  const {store} = data
  fetchMenu({store})
  await store.localization.getTranslations(store.localization.currentLocale)
  return Promise.resolve({store, ...data})
}

const updateCurrentLocale = async (
  {store, tokens}: {store: IRootStore, tokens: ITokens}) => {

  const trendsReportAudience = getConfig().get("audiences.trendsReport")
  const token = get(tokens.get(trendsReportAudience), "idToken")
  const userLocale = get(
    token, "https://identity.ingrooves.com/claims/user_metadata"
  )

  store.localization.updateCurrentLocale(userLocale.language)

  return Promise.resolve({store})
}

export const init = () => {
  return auth.authenticate()
  /* Logout the user if tokens reject */
  .catch(reason => {
    auth.identityClient.logout()
  })
  .then((tokens: Map<string, IToken>) => {
    const { intNav} = config.get("audiences")

    /* Initiate TrendsReportClient */
    const reporter = new TrendsReportClient({
      endpoint: config.get("endpoints.trendsReport")
    })

    const store = new RootStore(reporter)
    /* Initiate Searcher */

    const searcher = new Searcher()

    const logger = new Logger()

    if (config.get("features.uiTools")) {
      import(
        /* webpackChunkName: "uiTools" */
        "./lib/UITools/UITools"
      ).then(({default: UITools}) => {
        window.UITools = new UITools(store)
      })
    }

    /* Update the header with user info */
    store.identity.updateUserProfile({
      email: tokens.get(intNav).idToken.email
    })

    updateCurrentLocale({store, tokens})

    return Promise.resolve({
      store,
      auth,
      searcher,
      reporter,
      logger
    })
  })
  .then(fetchTranslations)
  .catch(reason => {
    return Promise.reject({auth, reason})
  })
}
