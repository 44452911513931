import debug from "debug"

const auth = debug("trendsnow:auth")
const filter = debug("trendsnow:filter")
const query = debug("trendsnow:query")
const mobx = debug("trendsnow:mobx")
const perf = debug("trendsnow:perf")
const err = debug("trendsnow:error")
const info = debug("trendsnow:info")

export {
  auth,
  filter,
  query,
  mobx,
  perf,
  err,
  info
}
