import React from "react"
import { includes, has } from "lodash"
import DropdownListItem from "./DropdownListItem"
import { IDropdownListProps, IOption } from "./interface"

interface DropdownListGroupProps extends IDropdownListProps {
  groupLabel: string
}

const DropdownListGroup: React.StatelessComponent<DropdownListGroupProps> =
  (props) => {
  return (
    <li className="dropdown-group">
      <h2 className="dropdown-group-header">{props.groupLabel}</h2>
      <ul
        className="dropdown-group-menu"
        aria-labelledby={props.groupLabel}>
        {
          props.options && props.options.map((option: IOption, i) => {
            return (
              <DropdownListItem
                value={option.value}
                displayName={option.displayName}
                key={`${option.value}-${i}`}
                keepOpen={props.keepOpen}
                selected={
                  props.allSelected ||
                  includes(props.selectedValues, option.value)
                }
                required={
                  has(option, "required") && (option as IOption).required
                }
                handleItemClick={option.required ? null : props.handleItemClick}
              />
            )
          })
        }
      </ul>
    </li>
  )
}

export default DropdownListGroup
