import { observable, action, computed } from "mobx"
import { isNil } from "lodash"
import { ICreateReportStore, IRootStore, FilterType, HandleValidationType } from "./types"
import * as notifications from "./notifications"
import { getFiltersValidation, getDateRangeValidation } from "./validation/validation"
import { config } from "../config"
import { ILookupFilterOption } from "../components/common/lookup-input/types"

class CreateReportStore implements ICreateReportStore {
  rootStore: IRootStore
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable reportSubmitting = false

  /*
    Restores reportHistory from web storage
   */

  @action updateReportSubmittingState = (state?: boolean) => {
    this.reportSubmitting = state || !this.reportSubmitting
  }

  /*
    Determines validation of all inputs.
    Validation requires a metric value, a breakout value, at least one
    filter value, and that all filters have selected values.
    @return {boolean} - true if the required data is ready
  */

  @action validateSubmission = () => {
    this.rootStore.notifications.clearNotificationsByType("submitReport")
    const submittable =
      this.validateMetric() &&
      this.validateDateRange() &&
      this.validateBreakout() &&
      this.validateFilters() &&
      this.validateFilterSelections()

    return submittable
  }

  /*
    Validates breakout
    @return { boolean } true if a metric is selected
  */

  @action validateMetric = () => {
    return this.handleValidation(
      "metric",
      !isNil(this.rootStore.filters.metric),
      notifications.metricError
    )
  }

  /*
    Validates breakout
    @return { boolean } true if a metric is selected and
    a breakout is selected
   */

  @action validateBreakout = () => {
    return this.handleValidation(
      "breakout",
      !isNil(this.rootStore.filters.metric) &&
      this.rootStore.breakouts.selectedBreakoutsColumns.length > 0,
      notifications.breakoutError
    )
  }

  /*
    Validates filters
    @return { boolean } true if a breakout is selected and
    at least one filter is active
   */

  @action validateFilters = () => {
    return this.handleValidation(
      "breakout",
      this.rootStore.breakouts.selectedBreakoutsColumns.length > 0 &&
      Object.keys(this.rootStore.filters.filters).length > 0,
      notifications.filtersError
    )
  }

  /*
    Validates filters selections
    @return { boolean } true if a breakout is selected and
    each filter has at least one selection
   */

  @action validateFilterSelections = () => {
    const validationResults = getFiltersValidation(
      this.rootStore.filters.filters
    )
    return this.handleValidation(
      "filterSelections",
      this.rootStore.breakouts.selectedBreakoutsColumns.length > 0 &&
      validationResults.isValid,
      notifications.makeFilterSelectionError(validationResults,
        this.rootStore.localization.translate)
    )
  }

  /*
    Validates date range
    @return { boolean } true if a metric is selected and
    dates are valid
   */

  @action validateDateRange = () => {
    return this.handleValidation(
      "dateRange",
      !isNil(this.rootStore.filters.metric) &&
      getDateRangeValidation(this.rootStore.filters.dateRange),
      notifications.dateRangeError
    )
  }

  /*
    Resets all the inputs
   */

  @action resetReportForm = () => {
    this.rootStore.filters.clearLookups();
    this.rootStore.notifications.clearAllNotifications();
    this.rootStore.filters.dateRange.clear();
    this.rootStore.filters.setMetric("Streaming");
    this.rootStore.breakouts.setBreakoutsList();
    this.rootStore.breakouts.setRequiredBreakouts();
  };

  /*
    Validates an input for a given validation and sets
    an error notification if not valid
    @param { string } prop - the input property
    @param { boolean } - the validation result
    @param { Notification } - the error to display
    @return { boolean } - the validation result
   */

  private handleValidation: HandleValidationType =
  (prop, validation, error) => {
    if (!validation) {
      this.rootStore.notifications.setNotifications({[prop]: error})
    } else if (this.rootStore.notifications.notifications.has(prop)) {
      this.rootStore.notifications.clearNotification(prop)
    }
    return validation
  }

  /*
    Computes if the report inputs are valid and that all required
    input is ready.
    @return {boolean} - true if the required data is ready
  */

  @computed get submittable() {
    return this.validateSubmission()
  }

  @computed get reportRequest() {
    const { breakouts, filters, localization } = this.rootStore;
    const reportingType = config.get("features.reportingType")

    let lookupFilters: ILookupFilterOption[]
      = filters.lookupFilters.map(filter => {
        if (!filter) {
          return null
        }
        return {
            column: filter.type,
            values: [filter.label]
        }
    });

    lookupFilters = lookupFilters.reduce((result, filter) => {
      if (!filter) { return result }
      const columnIndex = result.findIndex((lookup: ILookupFilterOption) => (
        lookup.column === filter.column
      ))
      if (columnIndex !== -1) {
        result[columnIndex].values =
          [...result[columnIndex].values, ...filter.values]
      } else {
        result.push({
          column: filter.column,
          values: filter.values
        })
      }
      return result
    }, [] as ILookupFilterOption[]) as ILookupFilterOption[]

    return {
      params: {
        reportName: filters.reportName,
        dateFrom: filters.dateRange.get("from"),
        dateTo: filters.dateRange.get("to"),
        locale: localization.currentLocale
      },
      data: {
        reportType: reportingType,
        columns: breakouts.selectedBreakoutsColumns,
        lookupFilters: lookupFilters,
        resultLimit: config.get("features.resultLimit"),
        filterBy: filters.filterables
        .filter((filter: FilterType) => filter.selectedValues.length > 0)
        .map((filter: FilterType) => {
          return {
            column: filter.id,
            operator: "IN",
            values: Array.from(filter.selectedValues)
          }
        })
        .concat( reportingType === "TRENDS" ? {
          column: "ConsumptionType",
          operator: "IN",
          values: [filters.metric]
        } : [])
      }
    }
  }
}

export default CreateReportStore
