import React from "react"
import { get } from "lodash"
import LogClient from "../../services/uiApi/LogClient"

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true})
    const logger = new LogClient()
    logger.logError({
      name: error.name,
      message: error.message,
      level: "error",
      data: {"stack": get(info, "componentStack")}
    })
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    } else {
      return null
    }
  }
}

export default ErrorBoundary
