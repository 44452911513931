import React from "react"
import { runInAction } from "mobx"
import { observer, inject } from "mobx-react"
import GlobalHeader from "@ingrooves/global-header.react"
import { stop } from "@ingrooves/browser-helpers"
import { INotificationsStore, IIdentityStore, IHeaderOptions } from "../../stores/types"
import { resetPasswordSubmitted, resetPasswordComplete } from "../../stores/notifications"
import { identityClient } from "../../config"

interface IProps {
  userEmail: string
  headerOptions: IHeaderOptions
  updateUserLanguage: (language: {id: string}) => Promise<any>
  // injected props
  notificationsStore?: INotificationsStore
  identityStore?: IIdentityStore
}

@inject("notificationsStore", "identityStore")
@observer
class Header extends React.Component<IProps> {
  constructor(props) {
    super(props)
    this.onResetPasswordClick = this.onResetPasswordClick.bind(this)
  }

  private get headerProps() {
    const {menu, ...options} = this.props.headerOptions
    return {
      ...menu,
      ...options,
      languageSelectCallback: (selection) => {
        this.props.updateUserLanguage(selection)
      }
    }
  }

  private async onResetPasswordClick(e: Event) {
    stop(e)
    this.props.notificationsStore.setNotifications(
      {
        [resetPasswordSubmitted.id]: resetPasswordSubmitted
      }
    );
    document.querySelector(
      "#global-header-component .navbar-user > .dropdown"
    ).classList.remove("open")
    await identityClient.requestPasswordReset(
      this.props.userEmail
    )
    runInAction(() => {
      setTimeout(() => {
        this.props.notificationsStore.clearNotification(
          resetPasswordSubmitted.id
        )
        this.props.notificationsStore.setNotifications({
          [resetPasswordComplete.id]: resetPasswordComplete
        })
      }, 1000)
    })
  }

  private get resetPasswordAnchor() {
    return document.querySelector(
      "#global-header-component .navbar-collapse a[href='resetPassword']"
    )
  }

  componentDidUpdate() {
    if (this.resetPasswordAnchor) {
      this.resetPasswordAnchor.addEventListener(
        "click",
        this.onResetPasswordClick
      )
    }
  }

  componentWillUnmount() {
    if (this.resetPasswordAnchor) {
      this.resetPasswordAnchor.removeEventListener(
        "click",
        this.onResetPasswordClick
      )
    }
  }

  render() {
    const {menu, activeProduct } = this.props.headerOptions
    const props = menu && GlobalHeader.transform(this.headerProps)
    return <GlobalHeader {...props} {...{activeProduct}} />
  }
}

export default Header
