import React, { useState } from "react"
import DatePicker from "react-datepicker"
import { endOfMonth, isSameMonth, startOfMonth } from "date-fns"
import classNames from "classnames"
import { monthDateRangeSelectorPresets, dayDateRangeSelectorPresets } from "../../../lib/date"
import InjectTranslatorHOC, { ITranslatorProps, Translate } from "../../InjectHOC"
import "react-datepicker/dist/react-datepicker.css"
import "./datepicker.scss"

interface IProps extends ITranslatorProps {
  monthPicker?: boolean
  dateFrom: Date
  dateTo: Date
  handleDateChange: (dateRange: Date[]) => void
}

interface IPreset {
  [rangeKey: string]: Date[]
}

interface IPresetProps {
  presets: IPreset[]
  presetClickHandler: (from: Date, to: Date) => void
  selectedRange: Date[]
  translate: Translate
}

const DatePickerPresets: React.FC<IPresetProps> = (props) => (
  <div className="presets">
    {
      props.presets.map((preset: IPreset, i: number) => {
        const label = Object.keys(preset)[0]
        const presetFrom = preset[label][0]
        const presetTo = preset[label][1]
        const selectedFrom = props.selectedRange[0]
        const selectedTo = props.selectedRange[1]
        let active = false
        if (selectedFrom && selectedTo) {
          if (
            selectedFrom.getTime() == presetFrom.getTime()
            && selectedTo.getTime() == presetTo.getTime()) {
              active = true
          }
        }
        return (
          <div
            key={i}
            className={classNames("preset", {"active": active})}
            onClick={() => props.presetClickHandler(presetFrom, presetTo)}>
              { props.translate(label) }
          </div>
        )
      })
    }
  </div>
)

const DateRangePicker: React.FC<IProps> = (props) => {
  const [selectionMode, setSelectionMode] = useState<Boolean>(true)
  const dateFormat = props.monthPicker ? "MM/yyyy" : "P"
  let dateRangeDisplay = ""
  if (props.dateFrom) {
    dateRangeDisplay = `${props.formatDateLocale(props.dateFrom, dateFormat)}`
    if (props.dateTo) {
      dateRangeDisplay = `${props.formatDateLocale(props.dateFrom, dateFormat)} - ${props.formatDateLocale(props.dateTo, dateFormat)}`
    }
  }

  let intervalProps = {}
  if (props.monthPicker) {
    intervalProps = {
      showMonthYearPicker: true,
      showFourColumnMonthYearPicker: true,
      showFullMonthYearPicker: true
    }
  } else {
    intervalProps = {
      monthsShown: 2
    }
  }

  const handleMonthlyDateChange = (dateRange: Date[]) => {
    const start = dateRange[0] ? startOfMonth(dateRange[0]) : null
    let end = dateRange[1] ? endOfMonth(dateRange[1]) : null
      if (!end && isSameMonth(start, new Date)) {
        end = endOfMonth(new Date)
      }
      setSelectionMode(!end)
    props.handleDateChange([start, end])
  }

  return (
    <DatePicker
      className="form-control pull-left"
      popperClassName={
        props.monthPicker && selectionMode ? "selection-mode" : ""
      }
      selected={props.dateFrom}
      onChange={
        (dateRange: Date[]) => {
          props.monthPicker
            ? handleMonthlyDateChange(dateRange)
            : props.handleDateChange(dateRange)
        }
      }
      startDate={props.dateFrom}
      endDate={props.dateTo}
      placeholderText={props.translate("dateRange.placeholder")}
      value={dateRangeDisplay}
      maxDate={props.monthPicker ? endOfMonth(new Date()) : new Date()}
      isClearable
      selectsRange
      showPopperArrow={false}
      locale={props.locale}
      dateFormat={dateFormat}
      openToDate={props.dateTo || null}
      { ...intervalProps }
    >
      <DatePickerPresets
        presets={props.monthPicker
          ? monthDateRangeSelectorPresets
          : dayDateRangeSelectorPresets}
        presetClickHandler={(from, to) => {
          props.monthPicker
          ? handleMonthlyDateChange([from, to])
          : props.handleDateChange([from, to])
        }}
        selectedRange={[props.dateFrom, props.dateTo]}
        translate={props.translate} />
    </DatePicker>
  )
}

export default InjectTranslatorHOC(DateRangePicker)

