import React from "react"
import Dropdown from "../dropdown/Dropdown"
import InputGroupAddon from "./../inputs/InputGroupAddon"
import { xor } from "lodash"
import "./labeled-dropdown.scss"
import { getValue } from "../shared/utils";
import { IOption } from "../dropdown/interface";

interface IProps {
  selectedValues: string[]
  options?: IOption[]
  allOption: IOption
  multiselect?: boolean,
  // Adds an "all" option at the top of the list
  includeAllOption?: boolean,
  // whether to make 'all' option checked (applies only when multiselect)
  allSelected: boolean
  // text to display when in the trigger area
  displayText?: string
  // the display text for the dropdown label
  label: string,
  onChange: (newVal: string[]) => void
}

/*
  LabeledDropdown component is a dropdown with a label
  to the left
*/

class LabeledDropdown extends React.Component<IProps> {
  static defaultProps = {
    selectedValues: []
  }

  constructor(props: IProps) {
    super(props)
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  /*
    Event handler for when user clicks a filter option.
    Calls props.onChange with a list of selected options.
    @param event: {MouseEvent} - the event object
  */

  handleItemClick(event) {
    const selected = event.currentTarget.dataset.value
    let nextSelectedList
    if (!this.props.multiselect) {
      nextSelectedList = [selected]
    } else {
      nextSelectedList = this.generateNextSelectionList(selected)
      this.props.onChange(nextSelectedList)
    }
    this.props.onChange(nextSelectedList)
  }


  /*
  Generates a list of selected options from the current selection
  and previous selection(s)
  @param {string} selection - the user-selected item
  @return {string[]} an array of all the selected items
*/

  private generateNextSelectionList = (selection: string) => {
    return (
      (selection == "all")
        ? this.props.allSelected
          ? []
          : this.props.options.map((o) => getValue(o))
        : xor(
            this.props.selectedValues,
            [selection]
          )
    )
  }

  render() {
    return (
      <div className="ing-labeled-dropdown">
        <InputGroupAddon label={this.props.label} />
        <Dropdown
          options={this.props.options}
          allOption={this.props.allOption}
          multiselect={this.props.multiselect}
          keepOpen={this.props.multiselect}
          selectedValues={this.props.selectedValues}
          displayText={this.props.displayText}
          allSelected={this.props.allSelected}
          handleItemClick={this.handleItemClick}
        />
      </div>
    )
  }
}

export default LabeledDropdown
