import { observable, action, computed } from "mobx"
import { values, get, has, orderBy } from "lodash"
import { IHistoryStore, ReportHistoryType, IRootStore } from "./types"
import * as debug from "./../lib/debuggers"

class HistoryStore implements IHistoryStore {
  rootStore: IRootStore
  poller

  @observable tableState = {
    pageSize: 10,
    page: 0,
    sorted: [{
      id: "id",
      desc: true as true
    }]
  }
  @observable total = 0

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  reportHistory = observable.array(
    <ReportHistoryType[]>[],
    { name: "reportHistory" }
  )

  @computed get recentReports(): ReportHistoryType[] {
    return orderBy(
      values(this.reportHistory), ["reportId"], ["desc"]
    )
  }

  @computed get totalPages() {
    return Math.ceil(this.total / this.tableState.pageSize)
  }

  @action updateReportHistory = (updates) => {
    this.reportHistory.replace(updates)
  }

  @action updateTableState = (state) => {
    const { page, pageSize, sorted} = state
    this.tableState = {
      pageSize, page, sorted
    }
    this.getHistory()
  }

  pollHistory = () => {
    this.getHistory()
    this.poller = setInterval(this.getHistory, 30000)
  }

  stopPollingHistory = () => {
    clearInterval(this.poller)
  }

  /*
    Queries the reports API for the user's report history
   */

  getHistory = () => {
    return this.rootStore.reporter.reportHistory({
      pageNumber: this.tableState.page,
      pageSize: this.tableState.pageSize,
      sortBy: this.tableState.sorted[0].id,
      desc: this.tableState.sorted[0].desc
    }).then(response => {
      // load total report count
      if (has(response, "data.reportCount")) {
        this.total = get(response, "data.reportCount")
      }

      // load reports into history
      if (has(response, "data.reports")) {
        const reports = get(response, "data.reports")
        this.updateReportHistory(reports)
        // stop poller when no reports are pending
        const hasPendingReports = reports.findIndex(
          (report: any) => {
            return report.completedAt == null || !(report.completedAt > 0)
          }
        ) >= 0
        if (!hasPendingReports) this.stopPollingHistory()
      }
    }).catch(reason => {
      // fail silently
      debug.err("Error loading reports. Reason:", reason)
    })
  }

  @computed get pendingReportIds() {
    return values(this.reportHistory)
      .filter(report => !report.completedAt)
      .map(report => report.id)
  }
}

export default HistoryStore
