import {IOption} from "./interface"

export const getValue = (option: string | IOption) => {
  return (typeof option == "string" && option) ||
         ((option as IOption).hasOwnProperty("value") &&
          (option as IOption).value) ||
         null
}

export const getDisplayName = (option: string | IOption) => {
  return (typeof option == "string" && option) ||
         ((option as IOption).hasOwnProperty("displayName") &&
          (option as IOption).displayName) ||
         ((option as IOption).hasOwnProperty("value") &&
          (option as IOption).value) ||
         null
}
