import React from "react"
import { observer } from "mobx-react"

interface IProps {
  label: string
}

const InputGroupAddon: React.StatelessComponent<IProps> = observer((props) => {
  return (
    <div className="input-group-addon">
      {props.label}
    </div>
  )
})

export default InputGroupAddon
