import React from "react"
import { has } from "lodash"
import { observer } from "mobx-react"

export const ReportHistoryRow = (props) => {
  return (<div>
    <div className={"filter-name"}>{props.filterName}</div>
    <div className={"filter-data"}>{props.filterData}</div>
  </div>)
}

const ReportHistoryCol = (props) => {
  return (<div className={"column"}>
    {props.filterIndices.map((filterIndex, i) => {
      const filterName = props.filters[filterIndex].column;
      const filterData = props.filters[filterIndex].values.join(", ");
      return <ReportHistoryRow
          key={i}
          filterName={filterName}
          filterData={filterData}/>
    })}
  </div>)
}

const ReportHistoryItemFiltration = observer((props) => {
  const hasFiltration = has(props, "colKeys") && has(props, "filters")
  return (
      <div className={"report-history-pivot flex-row flex-wrap"}>
        {hasFiltration ? props.colKeys.map((filterIndices, i) => {
          return <ReportHistoryCol
              key={i}
              filters={props.filters}
              filterIndices={filterIndices}/>
        }) :
          props.notAvailableMessage
        }
      </div>
  )
});

export default ReportHistoryItemFiltration
