import React from "react"

interface IProps {
  headerText: string
}

const ReportHistoryHeader = (props: IProps) => {
  return (
    <header className="report-history-header">
      <h1 className="h1">
        {props.headerText}
      </h1>
    </header>
  )
}

export default ReportHistoryHeader
