
import { inject } from "mobx-react"
import { IRootStore } from "../stores/types"
import { ILocalizationService } from "../services/LocalizationService"

export type Translate = (key: string) => string
export type FormatDateLocale = (date: Date, format: string) => string

export interface ITranslatorProps {
  translate?: Translate
  locale?: string
  formatDateLocale?: (date: Date, format: string) => string
}

interface IProvided {
  store: IRootStore
  localization: ILocalizationService
}

function InjectTranslatorHOC<P extends {}>(
  WrappedComponent: React.ComponentType<P>
) {
  return inject((provided: IProvided) => {
    return {
      translate: provided.localization.translate,
      locale: provided.localization.currentLocale,
      formatDateLocale: provided.localization.formatDateToLocale
    }
  })(WrappedComponent)
}

export default InjectTranslatorHOC
