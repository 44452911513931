import React from "react"

interface IProps {
  class?: string,
  alertId: string,
  alertType?: string,
  preMessage?: string
  message: string
  clearable?: boolean,
  clearAlert: (e: React.MouseEvent<HTMLElement>) => void
}

const Alert:React.StatelessComponent<IProps> = (props) => {
  const makeClassList = (props: IProps) => {
    return [
      "alert",
      props.class || "",
      (`alert-${props.alertType || "info"}`)
    ].join(" ")
  }

  return (
    <div
      className={makeClassList(props)}
      role="alert"
    >
      {props.preMessage &&
        <strong>{props.preMessage} </strong>}
      {props.message}
      {
        props.clearable &&
        <button
          type="button"
          className="close"
          aria-label="Close"
          data-id={props.alertId}
          onClick={props.clearAlert}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      }
    </div>
  )
}

export default Alert
