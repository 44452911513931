import { IBreakout, IDropdownFilter } from "./types"
import { includes } from "lodash"

/*
  About props:

  A breakout that is "visible" will appear in the breakouts list.
  A breakout that is "required" and "visible" will be checked.
  A breakout that is "required" but not "visible" will not appear
  in the breakouts list, but will have its columns included
  in the report.
 */

/*
  About columns:

  For each breakout selected by the user, the report request
  will include the "columns" for that breakout. If the breakout
  has no "columns" prop, it will include the "value" as a column.
  If "columns" is a function, it will be run with
  the corresponding filter as its only argument. The function
  will return an array of columns.
 */

export const breakoutDefinitions: { [key: string]: IBreakout } = {

label: {
  columns: ["labelName"],
  translationKey: "label",
  metric: "any",
  reportType: "BOTH",
  required: true,
  value: "label",
  visible: true
},

// Album Group
gtin: {
  columns: ["gtin", "albumName"],
  translationKey: "album",
  group: "albumGroup",
  metric: "any",
  reportType: "BOTH",
  primary: true,
  value: "gtin",
  visible: true
},
albumMainArtist: {
  translationKey: "breakout.albumMainArtist",
  group: "albumGroup",
  metric: "any",
  reportType: "BOTH",
  value: "albumMainArtist",
  visible: true
},
albumMasterGenre: {
  translationKey: "breakout.albumMasterGenre",
  group: "albumGroup",
  metric: "any",
  reportType: "BOTH",
  value: "albumMasterGenre",
  visible: true
},
albumGenre: {
  translationKey: "breakout.albumGenre",
  group: "albumGroup",
  metric: "any",
  reportType: "BOTH",
  value: "albumGenre",
  visible: true
},
albumReleaseDate: {
  translationKey: "breakout.albumReleaseDate",
  group: "albumGroup",
  metric: "any",
  reportType: "BOTH",
  value: "albumReleaseDate",
  visible: true
},

// Song Group
isrc: {
  columns: ["isrc", "songName", "songMix"],
  translationKey: "song",
  group: "songGroup",
  metric: "any",
  reportType: "BOTH",
  primary: true,
  value: "isrc",
  visible: true
},
songMainArtist: {
  translationKey: "breakout.songMainArtist",
  group: "songGroup",
  metric: "any",
  reportType: "BOTH",
  value: "songMainArtist",
  visible: true
},
songMasterGenre: {
  translationKey: "breakout.songMasterGenre",
  group: "songGroup",
  metric: "any",
  reportType: "BOTH",
  value: "songMasterGenre",
  visible: true
},
songGenre: {
  translationKey: "breakout.songGenre",
  group: "songGroup",
  metric: "any",
  reportType: "BOTH",
  value: "songGenre",
  visible: true
},
discNumber: {
  translationKey: "breakout.discNumber",
  group: "songGroup",
  metric: "any",
  reportType: "BOTH",
  value: "discNumber",
  visible: true
},
trackNumber: {
  translationKey: "breakout.trackNumber",
  group: "songGroup",
  metric: "any",
  reportType: "BOTH",
  value: "trackNumber",
  visible: true
},

// ************** SALES Breakouts *********************************************

// No Group
SalesData: {
  value: "SalesData",
  translationKey: "breakout.salesData",
  metric: "any",
  reportType: "SALES",
  visible: false,
  required: true,
  columns: ["quantity", "revenueUSD", "totalmechanicals", "netroyalty"]
},
statementName: {
  columns: ["statementname"],
  translationKey: "statement",
  metric: "any",
  reportType: "SALES",
  required: true,
  value: "statementName",
  visible: true
},
period: {
  reportType: "SALES",
  metric: "any",
  translationKey: "period",
  value: "period",
  visible: true
},
mainArtist: {
  columns: ["mainartist"],
  translationKey: "breakout.mainArtist",
  metric: "any",
  reportType: "SALES",
  required: false,
  value: "mainArtist",
  visible: true
},

// Album Group
catalogNumber: {
  translationKey: "breakout.catalogNumber",
  group: "albumGroup",
  metric: "any",
  reportType: "SALES",
  value: "catalogNumber",
  visible: true
},

// Retailer Group
retailerDisplayName: {
  reportType: "SALES",
  group: "retailerGroup",
  metric: "any",
  translationKey: "retailer.singular",
  value: "retailerDisplayName",
  visible: true,
  primary: true
},
retailerReportingPeriod: {
  translationKey: "breakout.retailerReportingPeriod",
  group: "retailerGroup",
  metric: "any",
  reportType: "SALES",
  required: false,
  value: "retailerReportingPeriod",
  visible: true
},
retailerTerritory: {
  reportType: "SALES",
  group: "retailerGroup",
  metric: "any",
  translationKey: "breakout.retailerTerritory",
  value: "retailerTerritory",
  visible: false
},

// Sales Type Group
salesTerritory: {
  columns: ["countryCodeA2", "countryName"],
  translationKey: "breakout.territory",
  group: "salesTypeGroup",
  metric: "any",
  reportType: "SALES",
  value: "countryCodeA2",
  visible: true
},
assetType: {
  reportType: "SALES",
  group: "salesTypeGroup",
  metric: "any",
  translationKey: "assetType",
  value: "assetType",
  visible: true
},
salesDescription: {
  reportType: "SALES",
  group: "salesTypeGroup",
  metric: "any",
  translationKey: "salesDescription",
  value: "salesDescription",
  visible: true
},
salesClassification: {
  reportType: "SALES",
  group: "salesTypeGroup",
  metric: "any",
  translationKey: "salesClassification",
  value: "salesClassification",
  visible: true
},

// ************** TRENDS Breakouts ********************************************

// No Group
retailer: {
  columns: (retailer: IDropdownFilter) => {
    return includes(retailer.selectedValues, "Pandora")
      ? ["retailer", "playType"]
      : ["retailer"]
  },
  translationKey: "retailer.singular",
  metric: "any",
  reportType: "TRENDS",
  required: true,
  value: "retailer",
  visible: true
},
date: {
  translationKey: "breakout.date",
  metric: "any",
  reportType: "TRENDS",
  value: "date",
  visible: true
},
ConsumptionType: {
  value: "ConsumptionType",
  translationKey: "breakout.consumptionType",
  metric: "any",
  reportType: "TRENDS",
  visible: false,
  required: true,
  columns: ["quantity", "ConsumptionType"]
},
saleType: {
  translationKey: "breakout.saleType",
  metric: "Download",
  reportType: "TRENDS",
  required: true,
  value: "saleType",
  visible: false
},
sub30: {
  translationKey: "breakout.sub30",
  metric: "Streaming",
  reportType: "TRENDS",
  value: "sub30",
  visible: false
},

// LISTENER Group
countryCodeA2: {
  columns: ["countryCodeA2", "countryName"],
  translationKey: "breakout.country",
  group: "listenerGroup",
  metric: "any",
  reportType: "TRENDS",
  value: "countryCodeA2",
  visible: true
},
deviceType: {
  translationKey: "breakout.deviceType",
  group: "listenerGroup",
  metric: "Streaming",
  reportType: "TRENDS",
  value: "deviceType",
  visible: true
},
membershipMode: {
  translationKey: "breakout.membershipMode",
  group: "listenerGroup",
  metric: "Streaming",
  reportType: "TRENDS",
  value: "membershipMode",
  visible: true
},
operatingSystem: {
  translationKey: "breakout.operatingSystem",
  group: "listenerGroup",
  metric: "Streaming",
  reportType: "TRENDS",
  value: "operatingSystem",
  visible: true
},

// Source Group
playlistIdentifier: {
  translationKey: "breakout.playlistIdentifier",
  group: "sourceGroup",
  metric: "Streaming",
  reportType: "TRENDS",
  value: "playlistIdentifier",
  visible: true
},
playlistName: {
  translationKey: "breakout.playlistName",
  group: "sourceGroup",
  metric: "Streaming",
  reportType: "TRENDS",
  value: "playlistName",
  visible: true
},
sourceOfPlay: {
  translationKey: "breakout.sourceOfPlay",
  group: "sourceGroup",
  metric: "Streaming",
  reportType: "TRENDS",
  value: "sourceOfPlay",
  visible: true
}
}
