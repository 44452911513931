import React from "react"

/*
  An SVG defs component for storing reusable
  SVG graphical objects (eg, fills)
*/

const Defs: React.StatelessComponent = () => {
  return (
    <svg
      style={{width: 0, height: 0, position: "absolute"}}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="270.2615%"
          y1="371.858%"
          x2="-112.8567%"
          y2="-39.8607%"
          id="diagonal-gradient-fill"
        >
          <stop stopColor="#3023AE" offset="0%"/>
          <stop stopColor="#53A0FD" offset="47.5248%"/>
          <stop stopColor="#EC51A8" offset="100%"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Defs
