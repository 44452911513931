import React from "react"
import { observer } from "mobx-react"
import { NotificationsType } from "./../../../stores/types"
import Alert from "./../../common/alerts/Alert"
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"

interface IProps extends ITranslatorProps {
  notifications?: NotificationsType,
  clearNotification: (notificationId: string) => void
}

/*
  The Filter section of the Create Report UI.
  Will contain an unknown number of Filter components,
  one for each Breakout.
*/

export const CreateReportNotificationsSection = observer(
  (props: IProps) => {
    const handleClearAlertClick = (e: React.MouseEvent<HTMLElement>) => {
      const notificationId = e.currentTarget.dataset.id
      props.clearNotification(notificationId)
    }

    return (
      <section className="create-report-notifications">
        {Array.from(props.notifications.values(), (notification, i) => {
          return (
            <Alert
              key={`notification-${notification.id || ""}-${i}`}
              message={props.translate(notification.messageKey)}
              preMessage={props.translate(notification.preMessageKey)}
              alertType={notification.alertType}
              clearAlert={handleClearAlertClick}
              clearable={notification.clearable}
              alertId={notification.id}
            />
          )
        })}
      </section>
    )
  }
)

export default InjectTranslatorHOC(CreateReportNotificationsSection)
