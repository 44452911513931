import { FilterType } from "./types"
import { interpolate } from "../components/common/InterpolatedString"

export const makeFilterSelectionError = (validationResults, translate) => {
  const plural = validationResults.invalidFilters.length > 1

  const filtersList = validationResults.invalidFilters.map(
    (filter: FilterType) => translate(filter.translationKey)
  ).join(", ")

  const messageTemplate = plural
    ? "alert.danger.filterSelections.message.plural"
    : "alert.danger.filterSelections.message.singular"

  const message =  interpolate({
      template: translate(messageTemplate),
      replacements: {
        LIST: {value: filtersList}
      }
    })

  return {
    id: "filterSelections",
    type: "submitReport",
    preMessageKey: "alert.preMessage.danger",
    invalidFilters: validationResults.invalidFilters,
    messageKey: message,
    messageFilters: filtersList,
    alertType: "danger"
  }
}

export const filtersError = {
  id: "filters",
  type: "submitReport",
  preMessageKey: "alert.preMessage.danger",
  messageKey: "alert.danger.filters.message",
  alertType: "danger"
}

export const breakoutError =  {
  id: "breakout",
  type: "submitReport",
  preMessageKey: "alert.preMessage.danger",
  messageKey: "alert.danger.breakout.message",
  alertType: "danger"
}

export const metricError = {
  id: "metric",
  type: "submitReport",
  preMessageKey: "alert.preMessage.danger",
  messageKey: "alert.danger.metric.message",
  alertType: "danger"
}

export const dateRangeError = {
  id: "dateRange",
  type: "submitReport",
  preMessageKey: "alert.preMessage.danger",
  messageKey: "alert.danger.dateRange.message",
  alertType: "danger"
}

export const reportSubmissionError = () => ({
  id: "reportSubmissionError",
  type: "submitReport",
  preMessageKey: "alert.preMessage.danger",
  messageKey: "alert.danger.reportSubmissionError.message",
  alertType: "danger",
  clearable: true
})

export const reportSubmissionSuccess = (response) => {
  const id = response.data.reportId
  return {
    id: `${id}`,
    type: "submitReport",
    preMessageKey: "alert.preMessage.success",
    messageKey: "alert.success.submitReport.message",
    alertType: "success",
    clearable: true
  }
}

export const resetPasswordSubmitted = {
  id: "resetPasswordSubmitted",
  type: "resetPassword",
  messageKey: "alert.success.resetPasswordSubmitted.message",
  alertType: "info",
  clearable: false
}

export const resetPasswordComplete = {
  id: "resetPasswordComplete",
  type: "resetPassword",
  messageKey: "alert.success.resetPasswordComplete.message",
  alertType: "success",
  clearable: true
}
