import { observable, action } from "mobx"
import { assign } from "lodash"
import { IIdentityStore, IRootStore, IHeaderOptions, IGlobalHeaderProps } from "./types"
import { auth } from "../services/auth0/Authenticator"
import { patchUserMetadata, getNav } from "../services/integratedNav/intNavClient"
import * as debug from "../lib/debuggers"
import { getConfig } from "../config"

class IdentityStore implements IIdentityStore {
  rootStore: IRootStore

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable headerOptions: IHeaderOptions = {
    menu: {} as IGlobalHeaderProps,
    logoutUrl: "javascript:trendsReportLogout()",
    changePasswordUrl: "resetPassword",
    tagManagerId: getConfig().get("gtmId"),
    activeProduct: "central",
    dynamicLogoBaseURI: getConfig().get("endpoints.dynamicLogo")
  }

  @observable userProfile = {}

  /*
    Updates intNav header with menu items
  */

  @action updateHeaderOptions = (options) => {
    assign(this.headerOptions, options)
  }

  @action updateUserProfile = (options) => {
    assign(this.userProfile, options)
  }

  updateUserLanguage = (language: {id: string}) => {
    return patchUserMetadata(language).then(
      response => {
        if (response.status === 200) {
          return auth.authenticate({forceAuthorize: true}).then(
            tokens => {
              return (
                this.rootStore.localization.updateCurrentLanguage(language.id)
                  .then(() => {
                    this.rootStore.localization.getTranslations(language.id)
                    // get new nav menu items
                    return getNav(this)
                  })
              )
            }
          )
        }
      }
    ).then(
      response => {
        debug.info("Language and menu items updated.")
      }
    ).catch(reason => {
      debug.err("Language and/or menu items update failed.", reason)
    })
  }
}

export default IdentityStore
