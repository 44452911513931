import React, { useMemo, useState } from "react"
import ReactTable from "react-table"
import {observer} from "mobx-react"
import { get, find } from "lodash"
import * as debug from "../../lib/debuggers"
import { IHistoryStore, ReportHistoryType } from "../../stores/types"
import ReportHistoryHeader from "./ReportHistoryHeader"
import * as reportHistoryTableProps from "./reportHistoryTableProps"
import "react-table/react-table.css"
import "./report-history.scss"
import { intersection } from "lodash"
import ReportHistoryItemFiltration from "./ReportHistoryItemFiltration"
import { breakoutDefinitions } from "../../stores/breakoutDefinitions"
import InjectTranslatorHOC, { FormatDateLocale, ITranslatorProps, Translate } from "../InjectHOC"
import { retailers } from "../../stores/constants"
import ConditionalRender from "../common/ConditionalRender"

export interface IProps {
  historyStore: IHistoryStore,
  getCSV: (id) => Promise<string>
}

// based on ranking set at backend (API):
const filtersOrder: string[] = [
  "Date Range",
  // top-level filters
  "label",
  "LabelName",
  "superLabel",
  "retailer",
  "date",
  "ConsumptionType",
  // album filters
  "album",
  "albumName",
  "albumGenre",
  "albumMasterGenre",
  "albumReleaseDate",
  "catalogNumber",
  // song filters
  "song",
  "songMix",
  "songMainArtist",
  "songGenre",
  "songMasterGenre",
  "discNumber",
  "trackNumber",
  // listener filters
  "countryCodeA2",
  "deviceType",
  "membershipMode",
  "operatingSystem",
  "customerGender",
  // source filters
  "playlistIdentifier",
  "playlistName",
  "sourceOfPlay",
  "playlistURL",
  "saleType",
  "retailerPriceCurrency",
  "retailerPrice",
  "quantity",
  "sub30",
  // Sales filters
  "retailerDisplayName",
  "retailerTerritory",
  "assetType",
  "salesDescription",
  "salesClassification",
  "period"
];

const findFilter = (filters, filterName) => filters.find(f => {
  return f.column == filterName
});

const transformFilterName = (filterName) => {
  switch (filterName) {
    case "song":
      return "song"
    case "album":
      return "album"
    case "sub30":
      return "reportMetrics.spotifySub30s"
    case "ConsumptionType":
      return "consumptionType"
    case "date":
      return "breakout.date"
    case "period":
        return "period"
    default:
      return get(breakoutDefinitions[filterName], "translationKey")
  }
}

const translateValues = (values, translate) => (
  values.map(value => {
    let translationKey
    switch (value) {
      case "day":
      case "month":
      case "year":
        translationKey = `interval.${value}`
        break
      default:
        translationKey = value.toLowerCase()
    }
    return translate(translationKey)
  })
)

const createLocalDateFromUTC = (date: Date): Date => {
  const day = String(date.getUTCDate()).padStart(2, "0")
  const month = String(date.getUTCMonth() + 1).padStart(2, "0")
  const year = date.getUTCFullYear()
  return new Date(
    `${year}-${month}-${day}T00:00`)
}

const generateDateRange = (
  historyRow: ReportHistoryType,
  formatDateLocale: FormatDateLocale): string => {
    // the historyRow timestamps coming from the API are UTC dates,
    // formatting this date on locale can cause our display date to be
    // off by one
  return (
    `${formatDateLocale(
      createLocalDateFromUTC(new Date(historyRow.reportDateRange.from)), "P")
    } - ${formatDateLocale(
      createLocalDateFromUTC(new Date(historyRow.reportDateRange.to)), "P")
    }`
  )
 }

const prepareFilters = (
  currentOrderedFilters, historyRow, translate, formatDateLocale) => {
  const dateRangeStr = generateDateRange(historyRow, formatDateLocale);
  const filterBy = historyRow.reportRequest.filterBy;
  const lookupFilters = historyRow.reportRequest.lookupFilters;

  return ["Date Range", ...currentOrderedFilters]
      .map(filterName => {
        switch (filterName) {
          case "Date Range":
            return {
              column: translate("dateRange"),
              values: [dateRangeStr]
            };
          case "Sub 30":
            const sub30 = findFilter(filterBy, filterName);
            const val = (sub30.values[0].toLowerCase() == "all");
            return {
              column: translate(transformFilterName(filterName)),
              values: [val]
            };
          case "retailer":
            const retailerFilter = findFilter(filterBy, filterName)
            let columnDisplayName = retailerFilter.values.length == 1
                ? translate("retailer.singular")
                : translate("retailer.plural")
            const retailerDisplayNames = retailerFilter.values.map(
              (filter) => find(
                retailers,
                (retailer) => retailer.value == filter
              ).translationKey || filter
            )
            return {
              column: columnDisplayName,
              values: retailerDisplayNames
            };
          default:
            const filter = (lookupFilters &&
                findFilter(lookupFilters, filterName))
                || (findFilter(filterBy, filterName))

            return {
              column: translate(transformFilterName(filterName)),
              values: translateValues(filter.values, translate)
            }
        }
      });
};

/*
  Generates props from data for filtration sub-component.
  Returns object: {colKeys, filters} or null if an error occurs.
*/

export const filtrationProps = (
  tableRow, translate: Translate, formatDateLocale: FormatDateLocale) => {
  try {
    const historyRow = tableRow.original;
    let lookupFilters = historyRow.reportRequest.lookupFilters || [];

    let finalFiltersList = [
      ...historyRow.reportRequest.filterBy,
      ...lookupFilters
    ];

    const currentOrderedFilters = intersection(
        filtersOrder,
        finalFiltersList.map(f => f.column)
    );

    const filters = prepareFilters(
      currentOrderedFilters,
      historyRow,
      translate,
      formatDateLocale
    );

    let colKeys = filters.map((v, i) => [[i]]);
    return {colKeys, filters}
  } catch (error) {
    debug.err(error)
    return null
  }
}

export const ReportHistory: React.FC<IProps & ITranslatorProps> =
  observer((props) => {
  const [ expandedRowIds, setExpandedRowIds ] =
    useState<{[key: number]: boolean}>({})

  const { historyStore, translate, formatDateLocale } = props

  const defaultSort = historyStore.tableState.sorted

  const expandedRowIndexes = useMemo<{[key: number]: boolean}>(() => {
    const expandedRowIndexes = {}
    historyStore.recentReports.map((report, reportIndex) => {
      if (report.id && expandedRowIds[report.id]) {
        expandedRowIndexes[reportIndex] = true
      }
    })
    return expandedRowIndexes
  }, [expandedRowIds, historyStore.recentReports])

  const toggleRowExpansion = (rowId: number) => {
    setExpandedRowIds(prevState => {
      const rowIds = {...prevState}
      rowIds[rowId] = !rowIds[rowId]
      return rowIds
    })
  }

  const handleFetchData = (state) => {
    historyStore.updateTableState(state)
  }

  const handleSubComponent = (tableRow) => {
    return (
      <ReportHistoryItemFiltration
        {...filtrationProps(tableRow, translate, formatDateLocale)}
        notAvailableMessage={
          translate("reportHistory.details.notAvailable")
        }
      />
    )
  }

  const getTdProps = (_state, rowInfo, column, _instance) => {
    return {
      onClick: () => {
        if (rowInfo && column.id !== "status") {
            const rowId = rowInfo.row.id
            toggleRowExpansion(rowId)
        }
      }
    }
  }

  return (
    <ConditionalRender shouldRender={historyStore.reportHistory.length > 0}>
      <div className="report-history">
        <ReportHistoryHeader
          headerText={translate("reportHistory.headerText")} />
        <ReactTable
            className={"-highlight"}
            data={historyStore.recentReports}
            columns={reportHistoryTableProps.getColumns(props)}
            defaultSorted={defaultSort}
            minRows={5}
            expanded={expandedRowIndexes}
            getTdProps={getTdProps}
            pages={historyStore.totalPages}
            pageSize={historyStore.tableState.pageSize}
            showPagination={true}
            previousText={translate("previous")}
            nextText={translate("next")}
            pageText={translate("page")}
            ofText={translate("of")}
            showPageSizeOptions={false}
            showPageJump={false}
            manual
            onFetchData={handleFetchData}
            SubComponent={handleSubComponent}
        />
      </div>
    </ConditionalRender>
  )
})

export default InjectTranslatorHOC(ReportHistory)
