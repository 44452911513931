import React from "react"

interface IProps {
  headerText: string
}

const CreateReportHeader = (props: IProps) => {
  return (
    <header className="create-report-header">
      <h1 className="h1">{props.headerText}</h1>
    </header>
  )
}

export default CreateReportHeader
