import * as debug from "../../lib/debuggers"
import APIClient from "../APIClient"
import LogClient from "../uiApi/LogClient"
import { IReporter, IReportRequest, IHistoryOptions } from "./types"
import { config } from "../../config"
import { ILogger } from "../uiApi/types"

const noApiEndpoint = () => Promise.reject({
  message: "Unable to connect to the database at this time.",
  id: "undefinedReportApiEndpoint"
})

class TrendsReportAPI  extends APIClient implements IReporter {
  private apiEndpoint
  private logger: ILogger
  constructor({endpoint}: {endpoint: string}) {
    super()
    this.audience = config.get("audiences.trendsReport")
    this.apiEndpoint = endpoint
    this.logger = new LogClient()
    this.handleRequestError = async (err) => {
      if (err) {
        debug.err("TrendsReportsAPI error (status " + err.status + "):", err)
        const { config, data, status } = err
        return await this.logger.logError({
          level: "error",
          name: "ErrorFromTrendsReportAPI",
          message: data && data.message,
          status: status,
          response: data,
          url: config && config.url,
          params: config && config.params,
          data: config && config.data && JSON.parse(err.config.data),
        })
      } else {
        return await this.logger.logError({
          level: "error",
          message: "Missing response from trends report API",
          response: {},
          url: config && config.url,
          params: config && config.params,
          data: config && config.data && JSON.parse(err.config.data),
        })
      }
    }
  }

  submitReport = async (reportRequest: IReportRequest) => {
    if (!this.apiEndpoint) return noApiEndpoint()

    return this.request({
      method: "post",
      url: `${this.apiEndpoint}/report/submit`,
      params: reportRequest.params,
      data: reportRequest.data
    })
  }

  reportStatus = (id: number | string) => {
    if (!this.apiEndpoint) return noApiEndpoint()

    return this.request({
      method: "get",
      url: `${this.apiEndpoint}/report/${id}/status`,
    })
  }

  reportHistory = (opts: IHistoryOptions) => {
    if (!this.apiEndpoint) return noApiEndpoint()

    return this.request({
      method: "get",
      url: `${this.apiEndpoint}/history/reports`,
      params: opts as any
    })
  }

  getCSVPresignedKey = (id: number | string) => {
    if (!this.apiEndpoint) return noApiEndpoint()

    return this.request({
      method: "get",
      params: {
        fileName: `trendsreport_${id}`
      },
      url: `${this.apiEndpoint}/report/${id}/csv/presigned`,
    })
  }

  generateDownloadURL = (presignedKey: string, id: number | string) => {
    const reportingType = config.get("features.reportingType").toLowerCase()
    if (!this.apiEndpoint) return noApiEndpoint()
    return Promise.resolve(
      `${this.apiEndpoint}/download?key=${encodeURIComponent(presignedKey)}&fileName=${reportingType}report_${id}.csv`
    )
  }

  // getApplicableBreakouts = () => {
  //   if (!this.apiEndpoint) return noApiEndpoint()
  //   return this.request({
  //     method: "get",
  //     params: {
  //       // will want to add reportType in here once API supports it
  //       // reportType should be set in env var and will be different for
  //       // each instance (TRENDS / SALES)
  //     },
  //     url: `${this.apiEndpoint}/lookup/columns`
  //   })
  // }
}

export default TrendsReportAPI

