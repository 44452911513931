import { get } from "lodash"
import singleton from "singleton"
import { ISearchOptions, LookupEndpoint, LookupType } from "./types"
import APIClient from "../../services/APIClient"
import { Album, Artist, Label, Song, Track } from "@ingrooves/content-search-dto"

export type LookupDocument = {
  id: number,
  artist?: {
    artistName: string
  },
  genre?: {
    genreName: string
  },
  fromHistory: any,
  gtin?: number,
  isrc?: string,
  label?: {
    labelId: number,
    labelName: string
  },
  mix?: string,
  name: string,
  score: number,
  _id: string,
  _type: LookupType
}

export interface LookupsData {
  _type: "song" | "album" | "artist" | "label";
  fromHistory?: boolean;

  song?: SongData;
  album?: AlbumData;
  artist?: ArtistData;
  label?: LabelData;
}

export interface SongData {
  name: string;
  primaryKey: string;
  mix?: string;

  album?: AlbumSnippet;
  artist: ArtistSnippet;
  label: LabelSnippet;
}

export interface AlbumData {
  primaryKey: string;
  name: string;

  artist: ArtistSnippet;
  label: LabelSnippet;
}

export interface ArtistData {
  name: string;
  label: LabelSnippet;
}

export interface AlbumSnippet {
  primaryKey: number;
  name: string;
}

export interface ArtistSnippet {
  name: string;
}

export interface LabelData {
  name: string;
  primaryKey: number;
  parentLabel?: LabelData;
}

export interface LabelSnippet {
  primaryKey: number;
  name: string;
}

type ContentSearchResult = Song | Track | Artist | Album | Label;

export type LookupsIndex = "labels" | "albums" | "songs"

class Searcher extends singleton {
  private apiClient = new APIClient

  constructor() {
    super()
  }

  static mapContentSearchResult(result: ContentSearchResult): LookupsData {
      const index = result._index
      const data: LookupsData = {
        _type: undefined,
        fromHistory: false,
      }
      switch (result._index) {
        case "songs":
          data._type = "song";
          data.song = {
            artist: {
              name: result.displayArtist,
            },
            label: {
              primaryKey: result.labelId,
              name: result.labelName,
            },
            name: result.songName,
            primaryKey: result.isrc,
            mix: result.songVersion,
          };
          data.label = {
            primaryKey: result.labelId,
            name: result.labelName,
          }
          break;
        case "artists":
          data._type = "artist";
          data.artist = {
            label: {
              primaryKey: result.labelId,
              name: result.labelName,
            },
            name: result.artistName,
          };
          data.label = {
            primaryKey: result.labelId,
            name: result.labelName,
          }
          break;
        case "albums":
          data._type = "album";
          data.album = {
            label: {
              primaryKey: result.labelId,
              name: result.labelName,
            },
            artist: {
              name: result.displayArtist
            },
            primaryKey: result.gtin,
            name: result.albumName
          };
          data.label = {
            primaryKey: result.labelId,
            name: result.labelName,
          }
          break;
        case "labels":
          data._type = "label";
          data.label = {
            primaryKey: result.labelId,
            name: result.labelName,
            parentLabel: result.parentLabelId
              ? {
                  primaryKey: result.parentLabelId,
                  name: result.parentLabelName,
                }
              : undefined
          };
          break;
        default:
          throw new Error(`Unknown content search result type ${index}`);
      }
      return data;
    }

  async getSuggestions(type: LookupEndpoint,
    opts: ISearchOptions): Promise<any> {
    let result
    try {
      result =
        await this.apiClient.getSearchContent(opts.q, type as LookupsIndex)
    } catch (e) {
      const resp = e.response;
      throw { error: get(resp, "data.msg.primary")
        || "error.unknown.primary", response: resp }
    }

    if (!result || result.data.length === 0) {
      throw { error: "noResultsFound" }
    }

    return result.data.map((d) => Searcher.mapContentSearchResult(d))
  }
}

export default Searcher
