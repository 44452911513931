import React from "react"
import FileDownload from "./FileDownload"
import { IProps } from "./ReportHistory"
import { Translate, ITranslatorProps, FormatDateLocale } from "../InjectHOC"

export interface HistoryRow {
  original: {
    id: string,
    status: {
      emptyReport: boolean
    }
  },
  value: any
}

class InfoCell extends React.Component<
  { row: HistoryRow; translate: Translate },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) return null;
    const row = this.props.row;
    try {
      return row.original.status.emptyReport
        ? this.props.translate("reportHistory.emptyReport")
        : row.value;
    } catch (e) {
      return row.value;
    }
  }
}

interface StatusCellProps{
  row: HistoryRow
  getCSV: (id) => Promise<string>
  translate: Translate
}

class StatusCell extends React.Component<StatusCellProps, {hasError: boolean}> {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  render() {
    const row = this.props.row
    if (this.state.hasError || !row.value) return null
    switch (row.value.state) {
      case "SUCCEEDED":
      case "SUCCESS":
        switch (row.value.emptyReport) {
          case true:
            return (
              <span className="flex-row">
                <i className="far fa-file color-neutral" />
                <span>{this.props.translate("noData")}</span>
              </span>
            )

          case false:
            return (
              <span className="cursor-pointer">
                <FileDownload
                  reportId={row.original.id}
                  getCSV={this.props.getCSV}
                >
                  <span>{this.props.translate("download")}</span>
                </FileDownload>
              </span>
            )

          default:
            return null
        }

      case "QUEUED":
        return (
          <span className="flex-row">
            <i className="fas fa-spinner fa-spin" />
            <span>{this.props.translate("queued")}</span>
          </span>
        )

      case "RUNNING":
      case "RESUMING_WAREHOUSE":
      case "QUEUED_REPAIRING_WAREHOUSE":
      case "BLOCKED":
        return (
          <span className="flex-row">
            <i className="fas fa-spinner fa-spin" />
            <span>{this.props.translate("running")}</span>
          </span>
        )

      case "CANCELLED":
        return (
          <span className="flex-row">
            <i className="far fa-times-circle color-neutral" />
            <span>{this.props.translate("cancelled")}</span>
          </span>
        )

      case "FAILED":
      case "FAILED_WITH_ERROR":
      case "FAILED_WITH_INCIDENT":
      case "ABORTING":
      case "ABORTED":
      case "DISCONNECTED":
        return (
          <span className="flex-row">
            <i className="fas fa-exclamation-triangle color-neutral" />
            <span>{this.props.translate("error")}</span>
          </span>
        )

      default:
        return null
    }
  }
}

class SubmittedCell extends React.Component<
  {date: string, format: FormatDateLocale}, {hasError: boolean}> {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) return null
    const date = Date.parse(this.props.date)
    return (
      <span>
        { date ? this.props.format(new Date(date), "PP") : null }
      </span>
    )
  }
}

export const getColumns = (props: IProps & ITranslatorProps) => {
  return props.historyStore.recentReports ? [
    {
      Header: props.translate("id"),
      maxWidth: 100,
      accessor: "id",
      defaultSortDesc: true
    }, {
      Header: props.translate("submitted"),
      accessor: "createdAt",
      maxWidth: 200,
      defaultSortDesc: true,
      Cell: row => (
        <SubmittedCell date={row.value} format={props.formatDateLocale} />
      )
    }, {
      Header: props.translate("name"),
      accessor: "name",
      minWidth: 200,
      defaultSortDesc: true
    },
    {
      Header: props.translate("info"),
      accessor: d => d.status && d.status.stateChangeReason || "",
      id: "info",
      sortable: false,
      maxWidth: 500,
      Cell: row => <InfoCell row={row} translate={props.translate} />
    },
    {
      Header: props.translate("status"),
      accessor: "status",
      sortable: false,
      id: "status",
      width: 175,
      Cell: row => (
        <StatusCell
          row={row}
          getCSV={props.getCSV}
          translate={props.translate}
        />
      )
    }] : []
}
