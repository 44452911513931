import { pick } from "lodash"
import { FilterType, IBaseFilter, DatesType } from "./../types"
import {
  IFilterValidationState,
  GetFiltersValidationType,
  FiltersValidationReducerType
} from "./types"

/*
  Map function for generating a validation state for each filter
  @return {FilterValidationState} an object containing the validation
  state of a single filter
*/

const validateFilter:
  (filter: FilterType) => IFilterValidationState = (filter) => {
    const { selectedValues, id, translationKey, type } = filter

    let isValid = false
    switch (type) {
      case "static":
        isValid = true
        break;

      // Not explicitly enumerating all types here, add exceptions above.
      default:
        isValid = ((filter as IBaseFilter).requiresValidation
          ? (selectedValues && selectedValues.length > 0)
          : true)
        break;
    }

    return { isValid, id, translationKey }
  }

/*
  Reduce function for arriving at an 'isValid' state for all filters
  @return {AllFiltersValidationState} - object containing validation state
*/

const filtersValidationReducer: FiltersValidationReducerType =
  (accumulator, currentFilter, index, filters) => {
    const currentFilterValidation = validateFilter(currentFilter)

    accumulator.isValid = accumulator.isValid && currentFilterValidation.isValid
    if (currentFilterValidation.isValid) {
      accumulator.validFilters.push(
        pick(currentFilterValidation, ["id", "translationKey"])
      )
    } else {
      accumulator.invalidFilters.push(
        pick(currentFilterValidation, ["id", "translationKey"])
      )
    }

    return accumulator
  }

/*
  Checks if all filters have options selected
  @param { Filters } filters - an object of filters
  @return { AllFiltersValidationState } object containing validation state
*/

export const getFiltersValidation: GetFiltersValidationType = (filters) => {
  const validationStates = Array.from(filters.values()).reduce(
    filtersValidationReducer,
    // Initial reduce value, isValid: true if there are filters
    {
      isValid: filters.size > 0,
      validFilters: [],
      invalidFilters: []
    }
  )

  return validationStates
}

/*
  Checks if a to and from daterange values are present
  @param { DatesType } dates - a map of 'from' and 'to' date strings
  @return { boolean } true if all selected filters
  have at least one option selected
*/

type DateRangeValidator = (dates: DatesType) => boolean

export const getDateRangeValidation: DateRangeValidator = (dates) => {
  return dates.has("from") && dates.has("to")
}
