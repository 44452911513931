import React from "react"
import classNames from "classnames"
import { IDropdownListItemProps } from "./interface"
import CheckIconGradientFill from "../icons/CheckIconGradientFill"
import CheckIconPlaceholder from "../icons/CheckIconPlaceholder"
import { observer } from "mobx-react"

/*
  A single li item within the dropdown list.
  Displays a checkmark (ok-sign) and applies 'selected' styling
  when the 'selected' prop is true.
*/

const DropdownListItem:
  React.StatelessComponent<IDropdownListItemProps>
  = observer((props) => {
    if (!props.value || !props.displayName) return null

    return (
      <li
        className={classNames({
          "selected": props.selected,
          "disabled": props.required
        })}
        onClick={props.handleItemClick}
        data-value={props.value}>
        <a>
          <span className="item-text">
            {props.displayName}
          </span>
          {props.selected || props.required ?
            <CheckIconGradientFill /> :
            <CheckIconPlaceholder />
          }
        </a>
      </li>
    )
  })

export default DropdownListItem
