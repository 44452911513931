import { IBreakoutsStore, IRootStore, BreakoutsType, IBreakout } from "./types"
import { breakoutGroups } from "./constants"
import { breakoutDefinitions as breakouts } from "./breakoutDefinitions"
import { observable, action, computed, reaction } from "mobx"
import { flatten, includes, pick, values, groupBy, mapValues, assign, uniq, reject, concat, filter } from "lodash"
import { config } from "../config"

class BreakoutsStore implements IBreakoutsStore {

  rootStore: IRootStore

  constructor(rootStore) {
    this.rootStore = rootStore
    this.breakoutReaction()
    this.metricReaction()
    this.localizationReaction()
  }

  @observable breakouts = {}

  @action translateBreakouts = () => {
    this.breakouts = mapValues(breakouts, (breakout) => {
      breakout.displayName = (
        this.rootStore.localization.translate(
          breakout.translationKey
        )
      )
      return breakout
    })
  }

  @computed get breakoutsByMetric() {
    return values(breakouts).filter(breakout => {
      return (
        (
          breakout.metric === this.rootStore.filters.metric ||
          breakout.metric === "any"
        )
      )
    })
  }

  breakoutsSelected = observable(
    <BreakoutsType>new Map(),
    { name: "selectedBreakouts"}
  )

  /*
    Sets the list of breakout options
  */

  @action setBreakoutsList = () => {
    this.breakouts = this.rootStore.filters.metric
      ? this.breakoutsByMetric.reduce(
          (acc, breakout: IBreakout) => {
            breakout.displayName =
              this.rootStore.localization.translate(breakout.translationKey)
            return {...acc, [breakout.value]: breakout}
          }, {}
        )
      : {}
  }

  /*
    Sets the required state of breakouts that have dependants
    @param { string<Array> } nextBreakoutsIds - a list of breakout ids
    @return { {[key: string]: IBreakout} } breakouts object
  */

  private updateRequiredBreakouts = (nextBreakoutsIds) => {
    const nextBreakouts = pick(this.breakouts, nextBreakoutsIds)

    // Breakouts with a 'primary' key
    const primaryBreakouts = values(nextBreakouts).filter(
      (bo: IBreakout) => bo.primary
    ).map((bo: IBreakout) => bo.value)

    // list of ids that are required
    const nextRequired = uniq(
      values(nextBreakouts).filter(
        (bo: IBreakout) => bo.group && !bo.primary
      ).map((bo: IBreakout) => {
        return breakoutGroups[bo.group].primaryId
      })
    )

    // update required state of primary breakouts
    primaryBreakouts.forEach((boID: string) => {
      const bo: IBreakout = nextBreakouts[boID]
      bo.required = includes(nextRequired, bo.value)
    })

    return nextBreakouts
  }

  /*
    Resets the selected breakouts to just
    those that are required or have already been selected
  */

  @action setRequiredBreakouts = () => {
    const reportingType = config.get("features.reportingType")
    this.setSelectedBreakouts(
      this.breakoutsByMetric.filter((bo: IBreakout) => {
        return bo.required && (bo.reportType === reportingType
                                || bo.reportType === "BOTH")
          || this.selectedBreakoutsKeys.includes(bo.value)
      }).map((bo: IBreakout) => bo.value)
    )
  }

  /*
    Sets the breakoutsSelected by the user
    @param { string<Array> } nextBreakoutsIds - a list of breakout ids
  */

  @action setSelectedBreakouts = (nextBreakoutsIds) => {
    if (includes(nextBreakoutsIds, "placeholder")) {
      this.breakoutsSelected.clear()
    } else {
      this.breakoutsSelected.replace(
        this.updateRequiredBreakouts(nextBreakoutsIds)
      )
    }
  }

  @action selectBreakout = (breakoutId) => {
    const currentIds = Array.from(this.breakoutsSelected.keys())

    includes(currentIds, breakoutId) ||
      this.setSelectedBreakouts(concat(currentIds, breakoutId))
  }

  @action deselectBreakout = (breakoutId) => {
    const currentIds = Array.from(this.breakoutsSelected.keys())

    includes(currentIds, breakoutId) &&
      this.setSelectedBreakouts(reject(currentIds, (e) => e == breakoutId))
  }

  /*
    Gets the columns associated with a particular breakout.

    The breakout.columns prop may be a function
    which is passed the current filter state so that
    columns can be calculated based on current selections

    Returns { string | string[]} -
    an array of column names or a column name.
   */

  private getBreakoutColumns = (breakoutName: string) => {
    let columns = breakouts[breakoutName].columns
    if (typeof columns === "function") {
      const filter = this.rootStore.filters.filters.get(breakoutName)
      columns = filter
        ? columns(this.rootStore.filters.filters.get(breakoutName))
        : null
    }
    return columns || breakouts[breakoutName].value
  }

  /*
    Gets a list of breakout columns for the current selected breakouts
    Return { string[]} - an array of column names
   */

  @computed get selectedBreakoutsColumns() {
    return flatten(
      Array.from(this.breakoutsSelected.values())
      .map((bo: IBreakout) => {
        return this.getBreakoutColumns(bo.value)
      })
    )
  }

  /*
    Gets a list breakout display names for rendering
    in the breakout dropdown trigger
    Return {string[]} - an array of display names
   */

  @computed get selectedBreakoutsDisplayNames() {
    const displayNames = reject(
      Array.from(this.breakoutsSelected.values()),
      (bo: IBreakout) => !bo.visible
    ).map(
      (breakout: IBreakout) => (
        this.rootStore.localization.translate(breakout.translationKey)
      )
    )
    return displayNames
  }

  @computed get selectedBreakoutsKeys() {
    const keys = filter(
      Array.from(this.breakoutsSelected.values()),
      (bo: IBreakout) => bo.visible
    ).map(
      (breakout: IBreakout) => breakout.value
    )
    return keys
  }

  @computed get breakoutGroupIds() {
    return Object.keys(this.groupedBreakouts)
  }

  @computed get groupedBreakouts() {
    const reportingType = config.get("features.reportingType")
    if (values(this.breakouts).length) {
      const applicableBreakouts = values(this.breakouts).filter(
        (breakout: IBreakout) => (
          breakout.reportType === reportingType
            || breakout.reportType === "BOTH"
        ))
      const visible = values(applicableBreakouts).filter(
        (breakout: IBreakout) => breakout.visible
      )

      // Group by breakout.group
      let groups = groupBy(
        visible, (breakout: IBreakout) => breakout.group
      )

      // pull out all breakouts without groupings
      const topLevel = pick(groups, ["undefined"]).undefined
      delete groups.undefined

      // map the groups for use into option groups for UI
      assign(
        groups,
        mapValues(groups, (group, key) => (
          {
            displayName: this.rootStore.localization.translate(
              breakoutGroups[key].translationKey),
            translationKey: breakoutGroups[key].translationKey,
            value: key,
            options: group
          }
          )
        )
      )

      // re-assign all the ungrouped breakouts
      topLevel.forEach(
        (breakout: IBreakout) => assign(groups, {[breakout.value]: breakout})
      )

      return groups
    }
    return this.breakouts
  }

  metricReaction = () => {
    return reaction(
      () => this.rootStore.filters.metric,
      () => {
        this.setBreakoutsList()
        this.setRequiredBreakouts()
        this.rootStore.filters.resetFilters()
      },
      {
        name: "metricReaction",
        fireImmediately: true
      }
    )
  }

  localizationReaction = () => {
    return reaction(
      () => this.rootStore.localization.translations,
      () => {
        this.translateBreakouts()
      }
    )
  }

  breakoutReaction = () => {
    return reaction(
      () => this.breakoutsSelected.keys(),
      () => {
        this.breakoutsSelected &&
          this.rootStore.filters.setFilters(
            Array.from(this.breakoutsSelected.keys())
          )
        !this.breakoutsSelected.size &&
          this.rootStore.notifications.notifications.has("filterSelections") &&
          this.rootStore.notifications.clearNotification("filterSelections")
      },
      {
        name: "breakoutReaction"
      }
    )
  }
}

export default BreakoutsStore
