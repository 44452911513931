import React from "react"
import { Provider, observer } from "mobx-react"
import { BrowserRouter } from "react-router-dom"
import { IRootStore } from "./../../stores/types"
import Routes from "./Routes"
import { IAuthenticator } from "./../../services/auth0/Authenticator"
import Searcher from "./../../services/lookups/searcher"
import { IReporter } from "./../../services/reports/types"
import { ILogger } from "./../../services/uiApi/types"
import SvgDefs from "./../common/icons/Defs"

interface IProps {
  logger: ILogger
  store: IRootStore
  auth: IAuthenticator
  searcher: Searcher
  reporter: IReporter
}

@observer
class App extends React.Component<IProps> {
  render() {
    return(
      <BrowserRouter>
        <div className="app-wrapper">
          {/* Definitions for SVG gradients used by all icons */}
          <SvgDefs />
          <Provider
            uiStore={this.props.store.ui}
            filtersStore={this.props.store.filters}
            identityStore={this.props.store.identity}
            breakoutsStore={this.props.store.breakouts}
            notificationsStore={this.props.store.notifications}
            createReportStore={this.props.store.createReport}
            historyStore={this.props.store.history}
            localization={this.props.store.localization}
            auth={this.props.auth}
            searcher={this.props.searcher}
            reporter={this.props.reporter}
            logger={this.props.logger}
            >
            <Routes />
        </Provider>
        </div>
      </BrowserRouter>
    )
  }
}

export default App
