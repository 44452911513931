import React from "react"
import { observer } from "mobx-react"
import Button from "./../../common/buttons/Button"
import Section from "./../../common/sections/Section"
import classNames from "classnames"
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"

/*
  Final section for submitting custom report
*/

interface IProps extends ITranslatorProps {
  handleSubmit: () => void
  handleClear: () => void
  reportSubmitting: boolean
}


@observer
export class CreateReportSubmit extends React.Component<IProps> {
  handleClear = (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.props.handleClear()
  }
  render() {
    return (
      <Section
        class={["create-report-submit", "flex-justify-end"]}
      >
        <a
          className={classNames(
              "btn",
              "create-report-clear",
              {"btn-disabled": this.props.reportSubmitting }
            )}
          title={this.props.translate("clearForm")}
          onClick={this.props.reportSubmitting ? null : this.handleClear}
        >{this.props.translate("clear")}
        </a>
        <Button
          style="primary"
          type="submit"
          text={
            this.props.reportSubmitting
              ? this.props.translate("submitting")
              : this.props.translate("submit")
          }
          iconClass={
            this.props.reportSubmitting ? "fas fa-spinner fa-spin" : ""
          }
          iconPosition="left"
          handleClick={this.props.handleSubmit}
          disabled={this.props.reportSubmitting}
        />
      </Section>
    )
  }
}

export default InjectTranslatorHOC(CreateReportSubmit)
