import React from "react"
import {components} from "react-select"
import InputGroupAddon from "./../inputs/InputGroupAddon"
import { IReactSelectProps } from "./types"

interface ControlProps extends IReactSelectProps {
  label: string
}

export default (props: ControlProps) => {
  const {children, label, ...restProps} = props
  return (
    <components.Control {...restProps}>
      <InputGroupAddon label={label} />
      {children}
    </components.Control>
  )
}
