import {get,merge} from "lodash"
import request from "./../lib/request"
import { identityClient, getConfig, config } from "../config"
import { handleTokenRejection, auth } from "./auth0/Authenticator"
import { Artist, Label, Song, Track } from "@ingrooves/content-search-dto"
import * as debuggers from "../debuggers"
import { LookupsIndex } from "./lookups/searcher"

type ContentSearchResult = Song | Track | Artist | Label
interface ContentSearchResponse {
  total: number,
  data: ContentSearchResult[]
}
type GetSearchContent =
  (searchStr: string, searchIndex: LookupsIndex) =>
    Promise<ContentSearchResponse>

export default class APIClient {
  protected audience
  protected handleRequestError
  protected request = async (opts) => {
    const { accessToken } = await identityClient.renew(
      this.audience
    )

    return request(merge(
      opts,
      {
        headers: { "Authorization": `Bearer ${accessToken}` }
      }
    ))
    .catch(err => {
      // handle all errors
      if (this.handleRequestError) {
        this.handleRequestError(get(err, "response"))
      }
      // logs out user if 401
      handleTokenRejection(get(err, "response"))
      return Promise.reject(err)
    })
  }

  protected getSession = () => {
    return identityClient.getSession(this.audience)
  }

  public getSearchContent: GetSearchContent =
    async (searchStr: string, searchIndex?: LookupsIndex) => {
    const token = await auth.getContentSearchToken()
    const apiURL = getConfig().get("endpoints.contentSearch")
    const url = `${apiURL}/search`
    const reportingType = config.get("features.reportingType")

    debuggers.query(`GET ${url}`)

    return request({
      url,
      headers: {
        "Authorization": `Bearer ${token.accessToken}`
      },
      params: {
        indices: searchIndex || "songs,albums,labels",
        q: searchStr,
        productTypes: (reportingType === "SALES") ?
            "Audio_Album,HQ_Audio_Album,Atmos_Audio_Album,Music_Video" :
            "Audio_Album",
        includeDeletedProducts: (reportingType === "SALES")
      },
    }).then(({ data }) => {
      debuggers.query(`${url} [response]: %o`, data)
      return data
    })
  }
}
