import React from "react"
import InputGroupAddon from "./../common/inputs/InputGroupAddon"
import { observer } from "mobx-react"

interface IProps {
  filterName: string,
  value: {
    displayName: string,
    value?: string
  }
}

const FilterInputStatic = observer((props: IProps) => {
  return (
    <div className="ing-labeled-static">
        <InputGroupAddon label={props.filterName} />
        <div className="ing-dropdown">
          <div className="input-group-static">
            <span>{props.value.displayName}</span>
          </div>
        </div>
    </div>
  )
})

export default FilterInputStatic
