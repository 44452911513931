import { reaction } from "mobx"

import {
  IBreakoutsStore,
  IFiltersStore,
  IUIStore,
  IIdentityStore,
  IHistoryStore,
  INotificationsStore,
  ICreateReportStore,
  IRootStore
} from "./types"

import { IReporter } from "./../services/reports/types"

import BreakoutsStore from "./BreakoutsStore"
import FiltersStore from "./FiltersStore"
import UIStore from "./UIStore"
import IdentityStore from "./IdentityStore"
import HistoryStore from "./HistoryStore"
import NotificationsStore from "./NotificationsStore"
import CreateReportStore from "./CreateReportStore"
import LocalizationService, { ILocalizationService } from "../services/LocalizationService"


class RootStore implements IRootStore {
  breakouts: IBreakoutsStore
  filters: IFiltersStore
  ui: IUIStore
  localization: ILocalizationService
  reporter: IReporter
  identity: IIdentityStore
  history: IHistoryStore
  notifications: INotificationsStore
  createReport: ICreateReportStore

  constructor(reporter: IReporter) {
    this.ui = new UIStore(this)
    this.localization = new LocalizationService()
    this.reporter = reporter
    this.identity = new IdentityStore(this)
    this.notifications = new NotificationsStore(this)
    this.filters = new FiltersStore(this)
    this.breakouts = new BreakoutsStore(this)
    this.history = new HistoryStore(this)
    this.createReport = new CreateReportStore(this)
  }

  retailerSub30Reaction = () => {
    const {spotifySelected, filters} = this.filters
    return reaction(
      () => spotifySelected,
      () => {
        if (!spotifySelected && filters.has("sub30")) {
          this.breakouts.deselectBreakout("sub30")
        }
      }, { name: "retailerSub30Reaction" }
    )
  }
}

export default RootStore
