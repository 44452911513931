import React from "react"
import { IReactSelectProps } from "./types"
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"
import { observer } from "mobx-react"

const CustomClearText: React.StatelessComponent<{clearText: string}> =
  ({clearText}) => {
  return (
    <div className="react-select__clear-indicator">
      <span>{clearText}</span>
    </div>
  )
}

interface IClearIndicatorProps extends IReactSelectProps {
  onMouseEnter: () => void
}

const ClearIndicator: React.StatelessComponent =
  observer((props: IClearIndicatorProps & ITranslatorProps) => {
  const {
    children = <CustomClearText clearText={props.translate("clearAll")} />,
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props

  return (
    <div
      className="react-select__clear-container"
      onMouseEnter={props.onMouseEnter}
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
        {children}
    </div>
  )
})

export default InjectTranslatorHOC(ClearIndicator)
