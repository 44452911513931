import React from "react"

const CheckIconGradientFill: React.StatelessComponent = (props) => {
  return (
    <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
      <path
        d={`M11.5 23C5.1487 23 0 17.8513 0 11.5S5.1487 0 11.5 0 23 5.1487 23 11.5 17.8513 23 11.5 23zm5.6222-14.0368a.7035.7035 0 0 0-.2031-.4933l-.9866-.9867a.7035.7035 0 0 0-.4933-.203.7035.7035 0 0 0-.4933.203l-4.759 4.7662-2.1328-2.14a.7035.7035 0 0 0-.4933-.2032.7035.7035 0 0 0-.4933.2032l-.9866.9866a.7035.7035 0 0 0-.2031.4933c0 .1813.0725.3627.2031.4933l3.6127 3.6127a.7035.7035 0 0 0 .4933.2031.7035.7035 0 0 0 .4934-.203l6.2388-6.239a.7035.7035 0 0 0 .2031-.4932z`}
        fill="url(#diagonal-gradient-fill)"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default CheckIconGradientFill
