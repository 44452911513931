import React from "react"
import { identityClient } from "../../config"

class Logout extends React.Component {
  componentWillMount() {
    identityClient.logout()
  }
  render() {
    return <div />
  }
}

export default Logout
