import debug from "debug"

const auth = debug("trendsreport:auth")
const lookups = debug("trendsreport:lookups")
const mobx = debug("trendsreport:mobx")
const perf = debug("trendsreport:perf")
const err = debug("trendsreport:error")
const info = debug("trendsreport:info")
const api = debug("trendsreport:api")

export {
  auth,
  api,
  lookups,
  mobx,
  perf,
  err,
  info
}
