import React from "react"
import { observer, inject } from "mobx-react"
import Section from "./../../common/sections/Section"
import Fieldset from "./../../common/fieldsets/Fieldset"
import Radio from "./../../common/inputs/Radio"
import Checkbox from "./../../common/inputs/Checkbox"
import { IFiltersStore, INotificationsStore, ICreateReportStore, IBreakoutsStore, IUIStore } from "./../../../stores/types"
import classNames from "classnames"
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"

interface IOption {
  value: string
  translationKey: string
}

interface IProps extends ITranslatorProps {
  options: { [key: string]: IOption },
  filtersStore?: IFiltersStore
  breakoutsStore?: IBreakoutsStore
  notificationsStore?: INotificationsStore
  createReportStore?: ICreateReportStore
  uiStore?: IUIStore
}

interface MetricOptionProps extends ITranslatorProps{
  option: IOption,
  selectedMetric: string,
  filtersStore: IFiltersStore,
  breakoutsStore: IBreakoutsStore,
  showSub30Toggle: boolean,
  setMetric: (nextMetric) => void
  handleSub30Selection: (nextVal: boolean) => void
}

@observer
export class MetricOption extends React.Component<MetricOptionProps> {
  render() {
    return (
      <div>
        <Radio
          value={this.props.option.value}
          displayName={ this.props.translate(this.props.option.translationKey) }
          name="metric"
          selected={this.props.selectedMetric == this.props.option.value}
          handleChange={this.props.setMetric}
        />

        {/* TODO: Move this into a separate context-sensitive "options" section
        elsewhere when we have more options to choose from */}

        {this.props.showSub30Toggle && this.props.option.value == "Streaming" &&
          <Checkbox
            displayName={this.props.translate("reportMetrics.spotifySub30s")}
            name="sub30"
            selected={this.props.filtersStore.sub30}
            disabled={!(this.props.selectedMetric == "Streaming")}
            handleChange={this.props.handleSub30Selection}
          />
        }
      </div>
    )
  }
}

@inject("filtersStore", "notificationsStore",
  "createReportStore", "breakoutsStore", "uiStore")
@observer
export class CreateReportMetricSelection extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.handleMetricSelection = this.handleMetricSelection.bind(this)
    this.handleSub30Selection = this.handleSub30Selection.bind(this)
  }

  handleMetricSelection(nextMetric: string) {
    this.props.filtersStore.setMetric(nextMetric)
    if (this.props.notificationsStore.notifications.has("metric")) {
      this.props.createReportStore.validateMetric()
    }
  }

  handleSub30Selection(nextSub30: boolean) {
    if (nextSub30) {
      this.props.breakoutsStore.selectBreakout("sub30")
    } else {
      // remove the breakout if not selected
      this.props.breakoutsStore.deselectBreakout("sub30")
    }
  }

  render() {
    const { filtersStore, notificationsStore, breakoutsStore } = this.props
    return (
      <Section
        class={classNames(
          ["create-report-metric", "flex-item", "flex-column"],
          { "invalid-input": notificationsStore.notifications.has("metric") }
        )}>
        <Fieldset legend={ this.props.translate("reportMetrics.header") } />
        {Object.keys(this.props.options).map((optionId: string, i: number) => {
          const option = this.props.options[optionId]
          return (
            <MetricOption
              option={option}
              showSub30Toggle={this.props.uiStore.features.showSub30Toggle}
              selectedMetric={filtersStore.metric}
              setMetric={this.handleMetricSelection}
              key={`${option.value}-${i}`}
              filtersStore={filtersStore}
              breakoutsStore={breakoutsStore}
              handleSub30Selection={this.handleSub30Selection}
              translate={this.props.translate}
            />
          )
        })}

      </Section>
    )
  }
}

export default InjectTranslatorHOC(CreateReportMetricSelection)
