import React from "react"
import { observer } from "mobx-react"
import CreateReportHeader from "./CreateReportHeader"
import CreateReportSelection from "./sections/CreateReportSelection"
import CreateReportFilterSection from "./sections/CreateReportFilterSection"
import CreateReportSubmit from "./sections/CreateReportSubmit"
import CreateReportNotificationsSection from "./sections/CreateReportNotificationsSection"
import { NotificationsType } from "./../../stores/types"

/* Create Report stylesheet */
import "./create-report.scss"

interface IProps {
  notifications: NotificationsType,
  clearNotification: (notificationId: string) => void
  handleSubmit: () => void
  handleClear: () => void
  reportSubmitting: boolean
  reportHeader: string
}

/*
  The Create Custom Report component
*/

const CreateReport = observer((props: IProps) => {
  return (
    <div id="create-report">
      <CreateReportHeader headerText={props.reportHeader} />
      <CreateReportNotificationsSection
        notifications={props.notifications}
        clearNotification={props.clearNotification}
      />
      <CreateReportSelection />
      <CreateReportFilterSection />
      <CreateReportSubmit
        handleClear={props.handleClear}
        handleSubmit={props.handleSubmit}
        reportSubmitting={props.reportSubmitting}
      />
    </div>
  )
})

export default CreateReport
