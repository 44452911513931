import React from "react"
import { values, xor, without, includes, intersection } from "lodash"
import { observer, inject } from "mobx-react"
import { breakoutGroups } from "./../../../stores/constants"
import classNames from "classnames"
import Dropdown from "./../../common/dropdown/Dropdown"
import Section from "./../../common/sections/Section"
import { IBreakout, IBreakoutsStore, INotificationsStore, IBreakoutGroup } from "./../../../stores/types"
import { computed } from "mobx"
import InjectTranslatorHOC, { ITranslatorProps } from "../../InjectHOC"
import { config } from "../../../config"

interface IProps extends ITranslatorProps {
  // callback for when an item is selected
  handleSelection?: (value: string) => void
  breakoutsStore?: IBreakoutsStore
  notificationsStore?: INotificationsStore
}

const placeholder = {
  value: "placeholder!",
  displayName: "select breakout"
}


@inject("breakoutsStore", "notificationsStore")
@observer
export class CreateReportBreakoutSelection extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  @computed get options() {
    const reportingType = config.get("features.reportingType")
    const { breakoutsStore } = this.props
    const groupsOrder = intersection(
      Object.keys(breakoutGroups),
      Object.keys(breakoutsStore.groupedBreakouts)
    )

    const groups = values(
      // order groups
      groupsOrder.map(group => (
        breakoutsStore.groupedBreakouts[group]
      ))
    ).map((group: IBreakoutGroup) => {
      // sort the group options alpha descending
      // with primary option first
      if (group && group.options) {
        group.options = group.options.sort((a, b) => {
          if (a.primary) return -1
          if (a === b) return 0
          return (a > b) ? -1 : 1
        })

        return group
      }
    })

    const options = values(breakoutsStore.groupedBreakouts)

    const requiredBreakouts = options.filter(
      (option: IBreakout) => option.required &&
          (option.reportType === reportingType ||
           option.reportType === "BOTH")
    )
    const topLevelBreakouts = options.filter(
      option => (
        !(option as IBreakoutGroup).options &&
        !(option as IBreakout).required
      )
    )

    return [
      ...requiredBreakouts,
      ...topLevelBreakouts,
      ...groups
    ]
  }

  private get getDropdownDisplayText() {
    return this.props.breakoutsStore.selectedBreakoutsDisplayNames
      .join(", ")
  }

  /*
    Generates a list of breakout ids toggling the current selection,
    including any group primary breakouts, and excluding any breakouts
    in conflicting groups
    @return { string[] } - A list of breakout ids
   */

  private generateNextSelectionList = (selection: string) => {
    const {
      breakouts, breakoutGroupIds, breakoutsSelected,
      groupedBreakouts: { listenerGroup, sourceGroup }
    } = this.props.breakoutsStore

    // Get a list of listener and source breakout IDs
    const listenerBreakoutIds = includes(breakoutGroupIds, "listenerGroup")
      ? (listenerGroup as IBreakoutGroup).options.map(opt => opt.value)
      : []

    const sourceBreakoutIds = includes(breakoutGroupIds, "sourceGroup")
      ? (sourceGroup as IBreakoutGroup).options.map(opt => opt.value)
      : []

    const selectedBreakout = breakouts[selection]
    let currentSelectedKeys = Array.from(breakoutsSelected.keys())

    // Include the primary group ID when any other items in group are selected
    if (!selectedBreakout.primary && selectedBreakout.group) {
      currentSelectedKeys.push(breakoutGroups[selectedBreakout.group].primaryId)
    }

    // Get symmetric difference of current and selected ids
    let nextBreakoutsList = xor(currentSelectedKeys, [selection])

    // handle mutually exclusive ids between listener and source group
    if (selectedBreakout.group == "listenerGroup") {
      return without(nextBreakoutsList, ...sourceBreakoutIds)
    }
    if (selectedBreakout.group == "sourceGroup") {
      return without(nextBreakoutsList, ...listenerBreakoutIds)
    }

    return nextBreakoutsList
  }

  /*
    Event handler for when user clicks a select list item.
    @param e: {FormEvent} - the event object
  */


  handleItemClick(event: React.MouseEvent<HTMLElement>) {
    const selected = event.currentTarget.dataset.value
    const nextSelectionList = this.generateNextSelectionList(selected)

    this.props.breakoutsStore.setSelectedBreakouts(nextSelectionList)
    if (this.props.handleSelection)
      this.props.handleSelection(selected)
  }

  render() {
    const { breakoutsStore, notificationsStore } = this.props
    const selectedValues = Array.from(breakoutsStore.breakoutsSelected.keys())

    return (
      <Section
        class={classNames(
          ["create-report-breakout", "flex-item-2", "flex-column"],
          { "invalid-input": notificationsStore.notifications.has("breakout") }
        )}
      >
        <label className="h2">{this.props.translate("filter.header")}</label>
        <Dropdown
          options={this.options}
          handleItemClick={this.handleItemClick}
          multiselect={true}
          disabled={Object.keys(breakoutsStore.breakouts).length == 0}
          keepOpen={true}
          displayText={ this.getDropdownDisplayText }
          selectedValues={
            (selectedValues.length && selectedValues) ||
            [placeholder.displayName]
          }
        />
      </Section>
    )
  }

}

export default InjectTranslatorHOC(CreateReportBreakoutSelection)
