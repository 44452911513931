import React from "react"
import Section from "./../../common/sections/Section"
import MetricSelection from "./CreateReportMetricSelection"
import DateRangeSelection from "./CreateReportDateRangeSelection"
import BreakoutSelection from "./CreateReportBreakoutSelection"
import { metrics } from "./../../../stores/constants"
import { config } from "../../../config"

/*
  First section of the Custom Create Report
  Subsections for Metric, DateRange, and Breakout Selection
*/

const CreateReportSelection = (props) => {
  const reportingType = config.get("features.reportingType")

  if (reportingType === "SALES") {
    return (
      <Section class={["create-report-selection","flex-row"]}>
        <DateRangeSelection />
        <BreakoutSelection />
      </Section>
    )
  } else {
    return (
      <Section class={["create-report-selection","flex-row"]}>
        <MetricSelection options={metrics} />
        <DateRangeSelection />
        <BreakoutSelection />
      </Section>
    )
  }
}

export default CreateReportSelection
