import React from "react"
import { observer, inject } from "mobx-react"
import Header from "./Header"
import Main from "./Main"
import Logout from "./Logout"
import { IIdentityStore } from "../../stores/types"

import {
  Route,
  Redirect,
  Switch
} from "react-router-dom"

export interface IProps {
  // injected props
  identityStore?: IIdentityStore
}

@inject("auth", "identityStore")
@observer
class Routes extends React.Component<IProps> {
  render() {
    return(
      <div className="flex-item flex-column">
        <Route path="/" render={() => {
          return (
            <Header
              headerOptions={this.props.identityStore.headerOptions}
              userEmail={this.props.identityStore.userProfile.email}
              updateUserLanguage={this.props.identityStore.updateUserLanguage}
            />
            )
          }}
          />
        <Switch>
          <Route path="/logout" render={() => {
            return <Logout />
          }} />
          <Redirect from="/login/callback" to="/" />
          <Route path="/" render={({location, history}) => {
            if (location.hash.length) history.replace("/")
            return <Main />
          }} />
        </Switch>
      </div>
    )
  }
}

export default Routes
