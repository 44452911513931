import { observable, action, computed, reaction } from "mobx"
import { format } from "date-fns-tz"

import { difference, values, pick, keys, filter, pickBy, compact, get, uniqBy, remove, reject, set, find, isEqual } from "lodash"
import * as debug from "../lib/debuggers"
import { filters, metrics, lookupFiltersMeta, lookupTypeMappings, retailers } from "./constants"
import { ILookupDocument } from "./../services/lookups/types"
import { IFiltersStore, FilterType, ILookupFilter, IDropdownFilter, FiltersType, DatesType, IRootStore } from "./types"
import { config } from "../config"

type GetTranslationProps = (props) => TranslationProp[]
type TranslationKey = string | GetTranslationProps
type TranslationProp = {
  prop: string
  key: TranslationKey
}
type TranslationProps = TranslationProp[]

// The filter properties that need to be translated

const translationProps: TranslationProps  = [
  {prop: "displayName", key: "translationKey"},
  {prop: "props.label", key: "props.labelKey"},
  {prop: "props.placeholder", key: "props.placeholderKey"},
  {prop: "props.noResults", key: "props.noResultsKey"},
  {prop: "props.options", key: (props) => props.map(
    prop => ({prop: "displayName", key: "translationKey"})
  )},
  {prop: "props.context", key: (props) => props.map(
    (prop, i) => (
      {
        prop: `props.context[${i}].label`,
        key: `props.context[${i}].labelKey`
      }
    )
  )}
]

class FiltersStore implements IFiltersStore {
  rootStore: IRootStore

  constructor(rootStore) {
    this.rootStore = rootStore
    this.filtersReaction()
    this.lookupsReaction()
    this.localizationReaction()
  }

  @observable metric = "Streaming"

  filters = observable(
    <FiltersType>new Map(),
    { name: "filters" }
  )

  /*
    Returns a value based on the current metric
    @param { function } f - A function with one argument, the current metric
  */

  private getDynamicProps = (f: (metric) => any) => {
    return f(this.metric)
  }

  /*
    Will translate any prop in filter defined in translationProps
    @param { [id, filter: IFilter] } - An array with the filer id
    and filter object
    @returns [id, filter: IFilter]
  */

  private translateFilter = ([id, filter]) => {
    const { translate } = this.rootStore.localization
    const translateProp = (translationProp: TranslationProp) => {
      let value = get(filter, translationProp.prop)
      // check if prop is dynamic
      if (typeof value === "function") {
        value = this.getDynamicProps(value)
      }
      const keyType = typeof translationProp.key

      switch (keyType) {
        case "function":
          // if key is a function, call with prop value
          const func = translationProp.key as GetTranslationProps
          if (value && func) {
            const translationProps = func(value)
            // set translations for each prop
            translationProps.forEach(translateProp)
          }
          break;

        case "string":
          const key = translationProp.key as string
          const translationKey = get(filter, key)
          // set translations for each prop
          if (value && key) {
            set(filter, translationProp.prop, translate(translationKey))
          }
          break;

        default:
          break;
      }
    }
    translationProps.forEach(translateProp)
    return [id, filter]
  }

  @action translateFilters = () => {
    this.filters.replace(
      Array.from(this.filters.entries()).map(this.translateFilter)
    )
  }

  @computed get filtersByMetric() {
    return pickBy(
      filters,
      (filter) => (
        filter.metric === this.metric ||
        filter.metric === "any"
      )
    )
  }

  dateRange = observable(
    <DatesType>new Map(),
    { name: "dateRange" }
  )

  lookupsSelected = observable(
    <ILookupDocument[]>[],
    { name: "lookupsSelected" }
  )

  /*
    Will initialize each filter to its default values.
    @param { array } filterIds - a list of filter id strings
   */

  private initializeFilters = (filterIds) => {
    const newFilterObjects = pick(this.filtersByMetric, filterIds)
    values(newFilterObjects).forEach((filter: FilterType) => {
      filter.selectedValues = this.getInitialFilterSelections(filter)
    })
    this.filters.merge(newFilterObjects)
    this.translateFilters()
  }

  /*
    Returns a list of default options for a filter
    @return {Array} - list of option strings
   */

  private getInitialFilterSelections = (filter: FilterType) => {
    switch (filter.type) {
      case "static":
      case "dropdown":
      case "hidden":
        const {defaultOptions, props} = filter as IDropdownFilter
        const options = typeof props.options === "function"
          ? props.options(this.metric)
          : props.options
        return (
          ((defaultOptions == "all" || defaultOptions == "any")
              && options.map((o) => o.value)) ||
          Array.isArray(defaultOptions) && defaultOptions
        )
      default:
        return []
    }
  }

  /*
    Sets the metric state
    @param { string } nextMetric - the id of the metric
  */

  @action setMetric = (nextMetric: string) => {
    this.metric = metrics[nextMetric].value
  }

  @action setDateRange = (startDate: Date, endDate: Date) => {
    this.dateRange.replace({
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd")
    })
  }

  @action clearDateRange = () => {
    this.dateRange.clear()
  }

  /*
    Updates filters based on breakouts selection.
    If a breakout is removed, it is deleted from filters.
    If a breakout is added, it is added to filters and
    initiated with default selectedValues.
    @param { string } nextFilters - list of filter ids
  */

  @action setFilters = (nextFilters: string[]) => {
    const filtersToAdd = difference(nextFilters, this.filtersList)
    const filtersToRemove = difference(this.filtersList, nextFilters)
    this.initializeFilters(filtersToAdd)
    if (filtersToRemove.length) {
      filtersToRemove.forEach(filterId => {
        this.filters.delete(filterId)
        remove(this.lookupsSelected, (doc) => {
            const f = lookupFiltersMeta[filterId]
            return f && doc._type == f.type
        })
      })
    }
  }

  /*
    Updates a filter with selected values from user
    input
    @param {string} filterId - the id of the filter
    @param {string[]} selection - an array of string values
  */

  @action updateFilter = (filterId: string, selection: string[]) => {
    this.filters.get(filterId).selectedValues = selection
  }

  /*
    Removes a lookup doc from the list of selected lookups if itemId is
    provided, else, removes all selected lookups
    @param {string} filterId - the id of the filter
    @param {number} itemId - the selected option id to be removed
   */

  @action clearSelectedLookup = (filterId: string, itemId?: number) => {
    const filter = this.filtersByMetric[filterId] as ILookupFilter

    if (!itemId) {
      this.lookupsSelected.replace([])
      return
    }

    const removed = this[lookupFiltersMeta[filterId].endpoint].find(item => (
      item.value === itemId))
    const keepable = reject(this.lookupsSelected, lookup => {
      const isPrimary = lookup._type == lookupFiltersMeta[filterId].type
      const isRemoveable = (valueKey) => {
        return removed.value === get(
          lookup, `${lookup._type}.${filter.props[valueKey]}`
        )
      }
      return isPrimary
        ? isRemoveable("valueField")
        : isRemoveable("valuePath")
    })
    this.lookupsSelected.replace(keepable)
  }

  @action clearLookups = () => {
    this.lookupsSelected.clear()
  }

  /*
    Replaces lookupsSelected with new lookup doc
    @param {string} filterId - the id of the filter
    @param {LookupDocument} lookup - a lookup document
  */

  @action updateLookupsSelected =
  (filterId: string, lookup: ILookupDocument, addLookup: boolean = false) => {
    const filterProps = (this.filters.get(filterId) as ILookupFilter).props
    const current = get(this.filters.get(filterId), "selectedValues")
    const isNew = !isEqual(current, [lookup[filterProps.valueField]])

    if (addLookup) {
      this.lookupsSelected.replace([...this.lookupsSelected, lookup])
      return
    }
    if (isNew) {
      this.lookupsSelected.replace([lookup])
    }
  }

  /*
    @typedef LookupSelection
    @property {string | number}
    LookupSelection.value - the value of the selection
    @property {string}
    LookupSelection.label - the display value of the selection
   */

  /*
    Generates a list of lookup values and labels
    @returns {LookupSelection[]}
   */

  private getLookupProps = (id: string, type: string) => {
    const lookups = compact(this.lookupsSelected.map(doc => {
      const filter = this.filters.get(id)
      if (filter) {
        const filterProps = (this.filters.get(id) as ILookupFilter).props
        const isType = doc._type == type
        let valueKey = isType ? filterProps.valueField : filterProps.valuePath
        let labelKey = isType ? filterProps.labelField : filterProps.labelPath
        const value = get(doc, `${doc._type}.${valueKey}`)
        const label = get(doc, `${doc._type}.${labelKey}`)
        return (label && value) ? { value, label, data: doc, type: type } : null
      }
      return null
    }))
    return lookups.length ? uniqBy(lookups, "value") : null
  }

  /*
    Computes a list of objects containing lookup value and label properties
    @returns {LookupSelection[]}
   */

  @computed get labels() {
    return this.getLookupProps("label", lookupFiltersMeta.label.type)
  }

  @computed get songs() {
    return this.getLookupProps("isrc", lookupFiltersMeta.isrc.type)
  }

  @computed get albums() {
    return this.getLookupProps("gtin", lookupFiltersMeta.gtin.type)
  }

  @computed get tracks() {
    return this.getLookupProps("track", lookupFiltersMeta.track.type)
  }

  @computed get lookupFilters() {
    return [].concat(this.labels).concat(this.albums).concat(this.songs);
  }

  @computed get sub30() {
    const sub30 = this.filters && this.filters.get("sub30")
    // To determine if the sub30 checkbox is checked, we need to make sure
    // there's no filter columns selected which includes TRUE, FALSE, and
    // NULL.
    return (sub30 && sub30.selectedValues[0] == "all") || false
  }

  @computed get lookupFiltersPendingClear() {
    if (this.lookupsSelected.length) {
      const lookup = this.lookupsSelected[0]
      const id = lookup && lookupTypeMappings[lookup._type]
      return id ? lookupFiltersMeta[id].dependents : []
    }
    return []
  }

  /*
    checks which filters need to be reset,
    sets these filters to the defaults for the current metric
  */

  @action resetFilters = () => {
    const filtersToReset = filter(
      this.filtersByMetric,
      filter => filter.resetOnMetricChange
    ).map(filter => filter.id)
    this.initializeFilters(filtersToReset)
    this.translateFilters()
  }

  /*
    Computes a list of filter ids –– based on the current breakout
    state –– for determining which filters to show in the UI.
    @return {string[]} - a list of filterIds
  */

  @computed get filtersList() {
    return Array.from(this.filters.values())
    .map(
      (filter: FilterType) => filter.id
    )
  }


  @computed get filterables() {
    return values(
      filter(Array.from(this.filters.values()),
        (filter: FilterType) => true
      )
    )
  }

  @computed get retailerCount() {
    const retailerFilter = this.filters.get("retailer")
    return retailerFilter && retailerFilter.selectedValues.length
  }

  @computed get spotifySelected() {
    const retailerFilter = this.filters.get("retailer")
    return retailerFilter && retailerFilter.selectedValues.includes("Spotify")
  }

  /*
    Computes a list of filter ids that depend
    on metric value
   */

  @computed get dependentFiltersList() {
    return keys(
      pickBy(
        filters,
        filter => filter.dependsOn == "metric"
      )
    )
  }

  @computed get lookupParams() {
    let params = {}
    Object.assign(params, {
      labelIds: get(this.filters.get("label"), "selectedValues", []).join(","),
      albumIds: get(this.filters.get("gtin"), "selectedValues", []).join(",")
    })
    debug.lookups("lookupParams:", params)
    return params
  }

  @computed get labelNames() {
    return this.labels ? this.labels.map((l) => l.label) : []
  }

  @computed get reportName() {
    const { translate } = this.rootStore.localization
    const reportingType = config.get("features.reportingType")
    // LABEL10CHR #-Retailers FROM-TO || #-Labels #-Retailers FROM-TO
    let labelString = ""
    if (this.labelNames.length === 1) {
      labelString = this.labelNames[0].substring(0, 20)
    } else {
      labelString = `${this.labelNames.length}-${translate("label.plural")}`
    }

    const from = this.dateRange.get("from")
    const to = this.dateRange.get("to")

    if (reportingType === "TRENDS") {
      let retailerString = ""
      if (this.retailerCount == 1) {
        const selectedRetailer = this.filters.get("retailer").selectedValues[0]
        retailerString = find(
          retailers,
          (retailer) => retailer.value == selectedRetailer
        ).translationKey || selectedRetailer
      } else {
        retailerString = `${this.retailerCount}-${translate("retailer.plural")}`
      }
      return `TRENDS - ${labelString} ${retailerString} ${from}-${to}`
    } else {
      return `SALES - ${labelString} ${from}-${to}`
    }
  }

  /*
    Generates a list of values from a list of selected lookup documents
    for a given filter id
    @type {object} Arg
    @property {string} Arg.id - the id of the filter
    @property {string} Arg.type -
    the type of the lookup doc ("albums", "songs", "labels")
    @param {Arg}
    @returns {Array} - An array of primitive values
   */

  generateSelectedValues = ({id, type}: {id: string, type: string}) => {
    return compact(this.lookupsSelected.map(lookup => {
      const filter = this.filters.get(id) as ILookupFilter
      const path = filter.props.valuePath
      return get(lookup, path)
    }))
  }

  /*
    Writes lists of selectedValues to filters store when lookups
    store is updated
    @returns {function} a disposer function
   */
  lookupsReaction = () => {
    return reaction(
      () => [
        this.lookupsSelected.map((lookup: ILookupDocument) => lookup._id),
        this.rootStore.localization.translations
      ],
      () => {
        let updates = {};
        keys(lookupFiltersMeta).forEach(filterId => {
          if (this.filters.has(filterId)) {
            let filter = this.filters.get(filterId)
            filter.selectedValues =
              this.generateSelectedValues(lookupFiltersMeta[filterId])
              Object.assign(updates, {
              [filterId]: this.filters.get(filterId)
            })
          }
        })
        this.filters.merge(updates)
      }
    )
  }

  /*
    Reacts to changes on the filter's selectedValues.
    Clears the filterSelections notification when a new filter is picked.
    @returns {function} a disposer function
  */

  filtersReaction = () => {
    return reaction(
      () => (
        Array.from(this.filters.values()).map(
          filter => filter.selectedValues
        )
      ),
      () => {
        if (this.rootStore.notifications.notifications.has("filterSelections")){
          this.rootStore.notifications.clearNotification("filterSelections")
        }
      },
      {
        name: "filterReaction"
      }
    )
  }

  /*
    Reacts to change on the translations definitions.
    Translates the properties in each filter that are displayed to user.
    @returns {function} a disposer function
  */

  localizationReaction = () => {
    return reaction(
      () => this.rootStore.localization.translations,
      () => {
        this.translateFilters()
      }
    )
  }
}

export default FiltersStore
