import { IHiddenFilter, IStaticFilter, IDropdownFilter, ILookupFilter } from "./types"
import { retailers, periods } from "./constants"

/*
  About filter.options:

  The "options" prop can be an array of options
  or a function that returns an array of options.
  The function takes one argument: the currently
  selected metric (string). This allows for options
  to changed based on the currently selected metric
  as is the case with the retailer filter.
*/

/*
  NOTE:
  Filters do not have a "visibility" prop.
  If you don't want breakout to show up as a filter,
  don't make a filter for it.
*/

export const gtin: ILookupFilter = {
  translationKey: "album",
  id: "gtin",
  metric: "any",
  props: {
    endpoint: "albums",
    valueField: "primaryKey",
    labelField: "name",
    valuePath: "album.primaryKey",
    labelPath: "album.name",
    multiselect: true,
    placeholderKey: "lookups.album.placeholder",
    placeholder: "Enter an album name or GTIN/UPC...",
    noResultsKey: "lookups.noResults",
    noResults: "No results found",
    context: [
      { field: "primaryKey", label: "GTIN", labelKey: "gtin", value: "" },
      { field: "label.name", label: "Label", labelKey: "label", value: "" }
    ]
  },
  type: "lookup"
}

export const isrc: ILookupFilter = {
  translationKey: "song",
  id: "isrc",
  metric: "any",
  props: {
    endpoint: "songs",
    valueField: "primaryKey",
    labelField: "name",
    valuePath: "song.primaryKey",
    labelPath: "song.name",
    multiselect: true,
    placeholderKey: "lookups.song.placeholder",
    placeholder: "Enter a song name or ISRC...",
    noResultsKey: "lookups.noResults",
    noResults: "No results found",
    context: [
      { field: "primaryKey", label: "ISRC", labelKey: "isrc", value: "" },
      { field: "mix", label: "Mix", labelKey: "mix", value: "" },
      { field: "artist.name", label: "Artist", labelKey: "artist", value: "" },
      { field: "label.name", label: "Label", labelKey: "label", value: "" }
    ]
  },
  type: "lookup"
}

export const label: ILookupFilter = {
  translationKey: "label",
  id: "label",
  metric: "any",
  required: true,
  requiresValidation: true,
  props: {
    endpoint: "labels",
    valueField: "primaryKey",
    labelField: "name",
    valuePath: "label.primaryKey",
    labelPath: "label.name",
    multiselect: true,
    placeholderKey: "lookups.label.placeholder",
    placeholder: "Enter a label name...",
    noResultsKey: "lookups.noResults",
    noResults: "No results found",
    context: [
      {
        field: "parentLabel",
        label: "Parent Label",
        labelKey: "label.parent",
        value: ""
      },
      {
        field: "topLevelLabel",
        label: "Top-Level Label",
        type: "boolean",
        labelKey: "label.topLevel",
        value: ""
      }
    ]
  },
  type: "lookup"
}

export const statementName: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "statement",
  id: "statementName",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const albumGenre: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.albumGenre",
  id: "albumGenre",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const albumMasterGenre: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.albumMasterGenre",
  id: "albumMasterGenre",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const albumMainArtist: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.albumMainArtist",
  id: "albumMainArtist",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const albumName: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "albumName",
  id: "albumName",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const albumReleaseDate: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.albumReleaseDate",
  id: "albumReleaseDate",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const catalogNumber: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.catalogNumber",
  id: "catalogNumber",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const countryCodeA2: IStaticFilter = {
  defaultOptions: "all",
  translationKey: "breakout.country",
  id: "countryCodeA2",
  metric: "any",
  props: {
    labelKey: "all",
    options: [
      { value: "all", translationKey: "all" },
    ]
  },
  type: "static"
}

export const countryName: IHiddenFilter = {
  defaultOptions: "all",
  translationKey: "countryName",
  id: "countryName",
  metric: "any",
  props: {
    labelKey: "all",
    options: [
      { value: "all", translationKey: "all" },
    ]
  },
  type: "hidden"
}

export const customerAgeRange: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.customerAgeRange",
  id: "customerAgeRange",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" }
    ]
  },
  type: "static"
}

export const deviceType: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.deviceType",
  id: "deviceType",
  metric: "Streaming",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const discNumber: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.discNumber",
  id: "discNumber",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const membershipMode: IStaticFilter = {
  defaultOptions: "all",
  translationKey: "breakout.membershipMode",
  id: "membershipMode",
  metric: "Streaming",
  props: {
    labelKey: "all",
    options: [
      { value: "all", translationKey: "all" },
    ]
  },
  type: "static"
}

export const operatingSystem: IStaticFilter = {
  defaultOptions: "all",
  translationKey: "breakout.operatingSystem",
  id: "operatingSystem",
  metric: "Streaming",
  props: {
    labelKey: "all",
    options: [
      { value: "all", translationKey: "all" },
    ]
  },
  type: "static"
}

export const customerGender: IStaticFilter = {
  id: "customerGender",
  translationKey: "breakout.customerGender",
  defaultOptions: "all",
  metric: "Streaming",
  props: {
    labelKey: "all",
    options: [
      { value: "all", translationKey: "all" }
    ]
  },
  type: "static"
}

export const playlistIdentifier: IStaticFilter = {
  defaultOptions: "all",
  translationKey: "breakout.playlistIdentifier",
  id: "playlistIdentifier",
  metric: "Streaming",
  props: {
    labelKey: "all",
    options: [
      { value: "all", translationKey: "all" },
    ]
  },
  type: "static"
}

export const playlistName: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.playlistName",
  id: "playlistName",
  metric: "Streaming",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const retailer: IDropdownFilter = {
  defaultOptions: "all",
  dependsOn: "metric",
  translationKey: "retailer.plural",
  id: "retailer",
  metric: "any",
  resetOnMetricChange: true,
  props: {
    multiselect: true,
    includeAllOption: true,
    options: (metric) => {
      return retailers.filter(opt => {
        return (
            metric === "any"
          || opt.metric === "any"
          || opt.metric === metric) && !opt.hidden
      })
    }
  },
  requiresValidation: true,
  type: "dropdown"
}

export const retailerReportingPeriod: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.retailerReportingPeriod",
  id: "retailerReportingPeriod",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const date: IDropdownFilter = {
  defaultOptions: ["year"],
  dependsOn: "metric",
  translationKey: "breakout.date",
  id: "date",
  metric: "any",
  resetOnMetricChange: true,
  props: {
    multiselect: false,
    includeAllOption: false,
    options: (metric) => {
      return periods.filter(opt => {
        return (
            metric === "any"
          || opt.metric === "any"
          || opt.metric === metric)
      })
    },
  },
  requiresValidation: true,
  type: "dropdown"
}

export const retailerDisplayName: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "retailer.singular",
  id: "retailerDisplayName",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const retailerTerritory: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.retailerTerritory",
  id: "retailerTerritory",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const saleType: IDropdownFilter = {
  defaultOptions: ["sale"],
  translationKey: "breakout.saleType",
  id: "saleType",
  metric: "Download",
  requiresValidation: true,
  props: {
    multiselect: true,
    includeAllOption: true,
    options: [
      { value: "sale", translationKey: "sale" },
      { value: "return", translationKey: "return" }
    ]
  },
  type: "dropdown"
}

export const songGenre: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.songGenre",
  id: "songGenre",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const mainArtist: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.mainArtist",
  id: "mainArtist",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const songMainArtist: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.songMainArtist",
  id: "songMainArtist",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const songMasterGenre: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.songMasterGenre",
  id: "songMasterGenre",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const songMix: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "songMix",
  id: "songMix",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const songName: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "songName",
  id: "songName",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const sourceOfPlay: IStaticFilter = {
  defaultOptions: "all",
  translationKey: "breakout.sourceOfPlay",
  id: "sourceOfPlay",
  metric: "Streaming",
  props: {
    labelKey: "all",
    options: [
      { value: "all", translationKey: "all" },
    ]
  },
  type: "static"
}

export const sub30: IHiddenFilter = {
  defaultOptions: "all",
  dependsOn: "metric",
  translationKey: "breakout.sub30",
  id: "sub30",
  metric: "Streaming",
  props: {
    options: [
      { value: "all", translationKey: "" },
    ]
  },
  type: "hidden"
}

export const trackNumber: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "breakout.trackNumber",
  id: "trackNumber",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const assetType: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "assetType",
  id: "assetType",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const period: IDropdownFilter = {
  defaultOptions: ["month"],
  dependsOn: "metric",
  translationKey: "period",
  id: "period",
  metric: "any",
  resetOnMetricChange: true,
  props: {
    multiselect: false,
    includeAllOption: false,
    options: () => {
      return periods.filter(opt => {
        return ( opt.value != "day" )
      })
    },
  },
  requiresValidation: true,
  type: "dropdown"
}

export const salesClassification: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "salesClassification",
  id: "salesClassification",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}

export const salesDescription: IStaticFilter = {
  defaultOptions: "any",
  translationKey: "salesDescription",
  id: "salesDescription",
  metric: "any",
  props: {
    labelKey: "any",
    options: [
      { value: "any", translationKey: "any" },
    ]
  },
  type: "static"
}
