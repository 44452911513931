import React from "react"
import { observer, inject } from "mobx-react"
import { get, includes, intersectionWith } from "lodash"
import { FilterType, UpdateFilterType, IIdentityStore, IFiltersStore, ILookupFilter, IDropdownFilter, IStaticFilter } from "../../stores/types"
import LookupInput from "../common/lookup-input/LookupInput"
import { ILookupDocument } from "./../../services/lookups/types"
import LabeledDropdown from "../common/labeled-dropdown/LabeledDropdown"
import FilterInputStatic from "./FilterInputStatic"
import Searcher from "../../services/lookups/searcher"
import {lookupFiltersMeta, allOption } from "./../../stores/constants"

import "./filter.scss"
import { IOption } from "../common/dropdown/interface"
import InjectTranslatorHOC, { ITranslatorProps } from "../InjectHOC"

interface IProps extends ITranslatorProps {
  // the filter object
  filter: FilterType
  // the callback for when filter item selection changes
  updateFilter: UpdateFilterType
  updateLookupFilters:
    (filterId: string, lookup: ILookupDocument, addLookup?: boolean) => void
  onMouseEnter: (e) => void
  onMouseLeave: (e) => void
  pendingClearVisible: boolean
  searcher?: Searcher
  identityStore?: IIdentityStore
  filtersStore?: IFiltersStore
  locked?: boolean
}

/*
  The Filter component is a dropdown which provides user control
  over which values will be requested for a given filter
*/

@inject("searcher", "identityStore", "filtersStore")
@observer
export class Filter extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleLookupChange = this.handleLookupChange.bind(this)
  }


  private translateOption = (option: IOption) => {
    const translatedOption = Object.assign({}, option)
    translatedOption.displayName = option.translationKey
      ? this.props.translate(option.translationKey)
      : option.displayName

    if (translatedOption.options) {
      translatedOption.options = translatedOption.options.map(
        option => this.translateOption(option)
      )
    }
    return translatedOption
  }

  handleLookupChange(document: ILookupDocument) {
    const filter = this.props.filter as ILookupFilter
    this.props.updateLookupFilters(filter.id, document, true)
  }

  handleChange(newVal: string[]) {
    this.props.updateFilter(
      this.props.filter.id,
      newVal
    )
  }
  allSelected = () => {
    const filter = this.props.filter as IDropdownFilter
    let options = get(filter, "props.options", false)
    if (options && typeof options === "function") {
      options = options(this.props.filtersStore.metric)
    }
    return (
      get(filter, "props.multiselect", false) &&
      options &&
      (options.length == filter.selectedValues.length)
    )
  }

  selectedDisplayNames = (options) => {
    const { filter } = this.props
    if (!options) return filter.selectedValues;
    return intersectionWith(
      options,
      filter.selectedValues,
      (option: IOption, selectedValue) => {
        return option.value === selectedValue
      }
    ).map(
      option => option.displayName
    )
  }

  getInputComponent() {
    const { filtersStore, filter } = this.props

    switch (filter.type) {
      case "lookup":
        const lookupsProp = lookupFiltersMeta[filter.id].endpoint
        const lookupFilter = filter as ILookupFilter
        return (
          <LookupInput
            onChange={this.handleLookupChange}
            onClear={(filterId, itemId) => (
              filtersStore.clearSelectedLookup(filterId, itemId))}
            pendingClearVisible={this.props.pendingClearVisible}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            isPendingClear={
              includes(
                filtersStore.lookupFiltersPendingClear,
                filter.id
              )
            }
            id={filter.id}
            {...lookupFilter.props}
            label={this.props.translate(filter.translationKey)}
            selectedLookups={filtersStore[lookupsProp]}
            multiselect
            isClearable
            noResults={
              lookupFilter.props.noResults
            }
            placeholder={
              lookupFilter.props.placeholder
            }
            locked={this.props.locked}
          />
        )
      case "dropdown":
        const allSelected = this.allSelected()
        const dropdownFilter = filter as IDropdownFilter
        let { options, includeAllOption, ...otherProps} = dropdownFilter.props
        if (typeof options === "function") {
          options = options(this.props.filtersStore.metric)
        }

        options = options.map(option => this.translateOption(option))
        return (
          <LabeledDropdown
            displayText={
              allSelected && this.props.translate(allOption.translationKey) ||
              this.selectedDisplayNames(options).join(", ")
            }
            selectedValues={dropdownFilter.selectedValues}
            onChange={this.handleChange}
            label={this.props.translate(dropdownFilter.translationKey)}
            allSelected={allSelected}
            options={options}
            allOption={
              includeAllOption
                ? this.translateOption(allOption)
                : null
            }
            {...otherProps}
          />
        )
      case "static":
        const staticFilter = filter as IStaticFilter
        return (
          <FilterInputStatic
            value={
              {displayName: this.props.translate(staticFilter.props.labelKey)}
            }
            filterName={
              this.props.translate(staticFilter.translationKey)
            }
          />
        )
      default:
        return null
    }
  }

  render() {
    return this.getInputComponent()
  }
}

export default InjectTranslatorHOC(Filter)
