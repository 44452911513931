import format from "date-fns/format"
import { get, isEmpty } from "lodash"
import { observable, action } from "mobx"
import { request } from "../lib/request"
import { auth } from "./auth0/Authenticator"
import { patchUserMetadata } from "./integratedNav/intNavClient"
import * as debug from "../lib/debuggers"
import englishTranslations from "../resources/translations/en/translations.json"
import { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import de from "date-fns/locale/de"
import ru from "date-fns/locale/ru"
import en from "date-fns/locale/en-US"
import fr from "date-fns/locale/fr"
import it from "date-fns/locale/it"
import ja from "date-fns/locale/ja"
import ko from "date-fns/locale/ko"
import pl from "date-fns/locale/pl"
import pt from "date-fns/locale/pt"
import tr from "date-fns/locale/tr"

export interface ITranslations {
  active: {
    [key: string]: string
  }
  fallback: {
    [key: string]: string
  }
}

export interface ILocalizationService {
  getTranslations(string): Promise<any>
  updateCurrentLanguage(locale: string): Promise<any>
  updateCurrentLocale(locale: string): void
  translate(key: string): string
  currentLocale: string
  translations: ITranslations
  formatDateToLocale: (date: Date, format: string) => string
}

class LocalizationService implements ILocalizationService {
  private localeDefault = "en"

  constructor() {
    this.locale = this.localeDefault
  }

  @observable private locale
  @observable public translations

  private dateLocales = {
    "es": es,
    "es-419": es,
    "de": de,
    "ru": ru,
    "en": en,
    "fr": fr,
    "it": it,
    "ja": ja,
    "ko": ko,
    "pl": pl,
    "pt": pt,
    "tr": tr
  }

  @action public formatDateToLocale = (date: Date, expectedFormat: string) => {
    return format(date, expectedFormat, {
      locale: this.dateLocales[this.locale] || en
    })
  }

  @action private updateTranslations = (translations) => {
    this.translations = translations
  }

  @action public updateCurrentLocale = (locale: string) => {
    if (locale !== this.locale) {
      this.locale = locale
    }
    registerLocale(locale, this.dateLocales[locale] || en)
    return Promise.resolve()
  }

  public get currentLocale() {
    return this.locale || this.localeDefault
  }

  private setEnglishTranslations = () => {
    const translations: ITranslations = {
      active: englishTranslations,
      fallback: {}
    }
    this.updateTranslations(translations)
    this.updateCurrentLocale("en")
  }

  /*
    Fetches translations from TR API
    and updates state
  */

  public getTranslations = async (locale) => {
    if (locale === "en") {
      this.setEnglishTranslations()
      return Promise.resolve(englishTranslations)
    } else {
      try {
        const token = await auth.getToken("trendsReport")
        const {data } = await request({
          url: "/api/v1/translations",
          headers: {
            "Accept-Language": locale,
            "Authorization": `Bearer ${token.accessToken}`
          }
        })
        if (data) {
          if (isEmpty(data.fallback)) {
            data.fallback = englishTranslations
          }
          this.updateTranslations(data)
          this.updateCurrentLocale(locale)
          return Promise.resolve(data)
        }
        return Promise.reject(`No translations for ${locale}`)
      } catch (error) {
        debug.err(error)
        this.setEnglishTranslations()
        return Promise.reject(error)
      }
    }
  }

  public translate = (key: string) => {
    if (!this.translations) return key

    return get(this.translations.active, key)
    || get(this.translations.fallback, key)
    || key
  }


  /*
    Patches Int Nav user metadata,
    updates local language state,
    fetches new tokens with forceAuthorize so that
    tokens have correct language in user metadata

    Returns a Promise
  */

  public updateCurrentLanguage = async (locale: string) => {
    try {
      await patchUserMetadata({id: locale})
      await auth.authenticate({forceAuthorize: true})
    } catch (error) {
      debug.err(error)
      return Promise.reject(error)
    }
    return Promise.resolve()
  }
}

export default LocalizationService
