import React from "react"
import classNames from "classnames"

interface IProps {
  // how the radio text should display, eg. 'Check me'
  displayName: string
  // the name of the checkbox, eg. 'mycheck'
  name: string
  // whether the option should be selected
  selected?: boolean
  disabled?: boolean
  // event listener for value change
  handleChange?: (nextVal: boolean) => void
}

/*
  A checkbox input that displays a single option
  within a list of radio options
*/

const Checkbox = (props: IProps) => {
  return (
    <div
      className={classNames("checkbox", props.name, {
        disabled: props.disabled,
      })}
    >
      <label>
        <input
          type="checkbox"
          name={props.name}
          checked={props.selected}
          onChange={(e) => props.handleChange(e.target.checked)}
          disabled={props.disabled}
        />
        {props.displayName}
      </label>
    </div>
  );
}

export default Checkbox
