import { MetricType } from "./types"
import { config } from "../config"
import * as filterDefinitions from "./Filters"

export const metrics: { [key: string]: MetricType } = {
  Streaming: {
    value: "Streaming",
    translationKey: "streams"
  },
  Download: {
    value: "Download",
    translationKey: "downloads"
  }
}

export const periods = [
  {
    value: "day",
    translationKey: "interval.day",
    metric: "any"
  },
  {
    value: "month",
    translationKey: "interval.month",
    metric: "any"
  },
  {
    value: "year",
    translationKey: "interval.year",
    metric: "any"
  }
]

export const retailers = [
  {
    value: "Google Music",
    translationKey: "Google Music",
    metric: "any"
  },
  {
    value: "7digital",
    translationKey: "7digital",
    metric: "Download"
  },
  {
    value: "Amazon",
    translationKey: "Amazon",
    metric: "Download"
  },
  {
    value: "Amazon Streaming",
    translationKey: "Amazon",
    metric: "Streaming"
  },
  {
    value: "Beat Port",
    translationKey: "Beat Port",
    metric: "Download"
  },
  {
    value: "ITunes",
    translationKey: "iTunes",
    metric: "Download"
  },
  {
    value: "Apple Music",
    translationKey: "Apple Music",
    metric: "Streaming"
  },
  {
    value: "Deezer",
    translationKey: "Deezer",
    metric: "Streaming"
  },
  {
    value: "Spotify",
    translationKey: "Spotify",
    metric: "Streaming"
  },
  {
    value: "YouTube",
    translationKey: "YouTube",
    metric: "Streaming"
  },
  {
    value: "Pandora",
    translationKey: "Pandora",
    metric: "Streaming",
    hidden: !config.get("features.showPandora")
  },
  {
    value: "Line Music",
    translationKey: "Line Music",
    metric: "Streaming"
  }
]

export const breakoutGroups = {
  // Shared Groups
  albumGroup: {
    primaryId: "gtin",
    name: "Album",
    translationKey: "breakoutGroup.Album"
  },
  songGroup: {
    primaryId: "isrc",
    name: "Song",
    translationKey: "breakoutGroup.Song"
  },

  // Sales Groups
  retailerGroup: {
    name: "Retailer",
    primaryId: "retailerDisplayName",
    translationKey: "breakoutGroup.Retailer"
  },
  salesTypeGroup: {
    name: "Sales",
    translationKey: "breakoutGroup.Sales"
  },

  // Trends Groups
  listenerGroup: {
    name: "Listener",
    translationKey: "breakoutGroup.Listener"
  },
  sourceGroup: {
    name: "Source",
    translationKey: "breakoutGroup.Source"
  }
}

export const filters = filterDefinitions

interface LookupMeta {
  id: string
  type: string
  endpoint: string
  dependents?: string[]
  sort: number
}

export const lookupTypeMappings = {
  label: "label",
  song: "isrc",
  album: "gtin"
}

export const lookupFiltersMeta: {[key: string]: LookupMeta} = {
  label: {
    id: "label",
    type: "label",
    endpoint: "labels",
    dependents: ["isrc", "gtin", "label"],
    sort: 0
  },
  gtin: {
    id: "gtin",
    type: "album",
    endpoint: "albums",
    dependents: ["gtin", "label"],
    sort: 1
  },
  isrc: {
    id: "isrc",
    type: "song",
    endpoint: "songs",
    dependents: ["isrc", "label"],
    sort: 2
  }
}

export const allOption = {
  value: "all",
  key: "all",
  translationKey: "all"
}
