import React from "react"
import classNames from "classnames"
import DropdownTrigger from "./DropdownTrigger"
import DropdownList from "./DropdownList"
import "./dropdown.scss"
import { IDropdownProps, IDropdownState} from "./interface"

/*
  Props

  options: an array of strings
  handleItemClick: callback for item (option) clicks
  multiselect: whether the dropdown allows user to select multiple items,
               default: false
  allSelected: whether the dropdown has all items selected, default: false
  handleTriggerClick: callback for trigger clicks
*/


class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  nodeRef: HTMLElement

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.handleTriggerClick = this.handleTriggerClick.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  /*
    Updates open state
  */

  setIsOpen = (isOpen: boolean) => {
    this.setState({isOpen})
  }

  /*
    Applies an event listener to the document when component mounts
    to handle clicks off component
  */

  listenForOutsideClick() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  /*
    Removes event listener from document which listens for off
    component clicks
  */

  removeOutsideClickListener() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  /*
    Remove outside click listener before unmounting
   */

  componentWillUnmount() {
    this.removeOutsideClickListener()
  }

  /*
    @param target: the node that received the event
    returns {boolean} - true if the dropdown is open and
    contains the target node
  */

  isOutsideClick(target) {
    return (
      this.state.isOpen &&
      this.nodeRef &&
      !this.nodeRef.contains(target)
    )
  }

  /*
    Event listener for off component mousedown
    @param event: {MouseEvent} - the event object
  */

  handleClickOutside(event) {
    if (this.isOutsideClick(event.target)) {
      this.setState({isOpen: false})
      this.removeOutsideClickListener()
    }
  }

  /*
    Event handler for when user clicks a dropdown item.
    Closes the dropdown if keepOpen props isn't true.
    Calls handleItemClick prop with the event.
    @param event: {MouseEvent} - the event object
  */

  handleItemClick = (event) => {
    if (!this.props.keepOpen) {
      this.setState({isOpen: false})
    }
    this.props.handleItemClick(event)
  }

  /*
    Event handler for when user clicks the dropdown trigger.
    Toggles the open state of the dropdown.
    Calls the handleTriggerClick prop, if provided, with event.
    @param event: {MouseEvent} - the event object
  */

  handleTriggerClick(e) {
    if (!this.state.isOpen) this.listenForOutsideClick()
    this.setIsOpen(!this.state.isOpen)
    this.props.handleTriggerClick && this.props.handleTriggerClick(e)
  }

  render() {
    return (
      <div
        className={
          classNames(
            "ing-dropdown",
            {"open": !this.props.disabled && this.state.isOpen}
          )
        }
        ref={ref => this.nodeRef = ref}
      >
        <DropdownTrigger
          id={this.props.id}
          maxWidth={this.props.maxTriggerWidth}
          keepOpen={this.props.keepOpen}
          displayText={this.props.displayText}
          disabled={this.props.disabled}
          handleTriggerClick={this.handleTriggerClick}
          selectedValues={this.props.selectedValues}
        />
        <DropdownList
          selectedValues={this.props.selectedValues}
          options={this.props.options}
          allOption={this.props.allOption}
          keepOpen={this.props.keepOpen}
          allSelected={this.props.allSelected}
          multiselect={this.props.multiselect}
          handleItemClick={this.handleItemClick}
        />
      </div>
    )
  }
}

export default Dropdown
