import React from "react"
import {includes, has} from "lodash"
import {IDropdownListProps, IOption} from "./interface"
import DropdownListItem from "./DropdownListItem"
import DropdownListGroup from "./DropdownListGroup"

/*
  A ul list of items to display within the dropdown.
  Only shows "all" option if 'multiselect' prop is true.
*/

const DropdownList: React.StatelessComponent<IDropdownListProps> = (props) => {
  const {options, ...otherProps} = props

  return (
    <ul
      className="dropdown-menu dropdown-menu-left"
      aria-labelledby={props.id}>
      { props.multiselect && props.allOption &&
        <DropdownListItem
          value={props.allOption.value}
          displayName={props.allOption.displayName}
          key={props.allOption.key}
          keepOpen={props.keepOpen}
          selected={props.allSelected}
          handleItemClick={props.handleItemClick}
        />
      }

      {options && options.map((option: IOption, i) => {
        if (option.options) {
          return (
            <DropdownListGroup
              groupLabel={option.displayName}
              options={option.options}
              key={`${option.value}-${i}`}
              {...otherProps}
            />
          )
        } else {
          return (
            <DropdownListItem
              value={option.value}
              displayName={option.displayName}
              key={`${option.value}-${i}`}
              keepOpen={props.keepOpen}
              selected={
                props.allSelected ||
                includes(props.selectedValues, option.value)
              }
              required={has(option, "required") && (option as IOption).required}
              handleItemClick={option.required ? null : props.handleItemClick}
            />
          )
        }
      })}
    </ul>
  )
}

export default DropdownList
