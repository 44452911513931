import React from "react"
import Shiitake from "shiitake"
import InjectTranslatorHOC from "../../InjectHOC"
import { observer } from "mobx-react"

const LookupSingleValue = observer((props) => {
  return (
    <span className="labeled-lookup-option">
      <Shiitake
        lines={2}
        throttleRate={200}
        className="labeled-lookup-option"
        tagName="span"
      >
        {props.data.label}
      </Shiitake>
    </span>
  );
})

export default InjectTranslatorHOC(LookupSingleValue)
