import { IdentityClient } from "@ingrooves/identity-web"
import Configuration from "in.environment-configuration.nodejs"

const configurable = new Configuration()

export const getConfig = () => configurable.fromEnvironment(
  window.trendsReportConfig || {}
)

export const config = getConfig()

const webConfig = {
  domain: config.get("auth0.domain"),
  clientID: config.get("auth0.clientID")
}

const contentSearchWebConfig = {
  clientID: config.get("auth0.contentSearchClientID"),
  domain: config.get("auth0.domain"),
}

export const identityClient = new IdentityClient(webConfig)

export const identityClientContentSearch = new IdentityClient(
  contentSearchWebConfig
)
export interface IIdentityClient {
  logout: () => void
  requestPasswordReset: (userEmail: string) => void
  renew: (audience, options) => Promise<any>
}
